import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { localeChanged, localize } from 'vee-validate';
import en from '../../../../../config/locales/frontend.en.yml';
import signupEn from '../../../../../config/locales/signup.en.yml';
// normalize task will remove duplicates from files, so we need both imports
import enVue from '../../../../../config/locales/vue.en.yml';

import vuexyEn from './locales/en.json';

Vue.use(VueI18n);

const messages = {
    en: {
        ...vuexyEn,
        ...enVue.en,
        ...signupEn.en,
        ...en.en,
    },

};

export const $t = (...args) => LBXFrontend.$t(...args);

const i18n = new VueI18n({
    locale: window.LBXFrontendData.locale || 'en',
    fallbackLocale: 'en',
    pluralizationRules: {
        en: (inputChoice, choicesLength) => {
            // '1 apple | 2 apples | 0 apples'
            let ret = 1;
            const choice = Math.abs(inputChoice);

            if (choice === 1) {
                ret = 0;
            } else if (choice === 0) {
                ret = 2;
            }

            return ret < choicesLength ? ret : choicesLength - 1;
        },
        sk: (inputChoice, choicesLength) => {
            // '1 jablko | 2-4 jablka | 0;5+ jablk'
            // or
            // '1 jablko | 2-4 jablka | 5+ jablk | 0 jablk'
            let ret = 2;
            const choice = Math.abs(inputChoice);

            if (choice === 1) {
                ret = 0;
            } else if (choice > 1 && choice < 5) {
                ret = 1;
            } else if (choice === 0 && choicesLength === 4) {
                ret = 3;
            }

            return ret < choicesLength ? ret : choicesLength - 1;
        },
        cs: (inputChoice, choicesLength) => {
            // '1 jablko | 2-4 jablka | 0;5+ jablk'
            // or
            // '1 jablko | 2-4 jablka | 5+ jablk | 0 jablk'
            let ret = 2;
            const choice = Math.abs(inputChoice);

            if (choice === 1) {
                ret = 0;
            } else if (choice > 1 && choice < 5) {
                ret = 1;
            } else if (choice === 0 && choicesLength === 4) {
                ret = 3;
            }

            return ret < choicesLength ? ret : choicesLength - 1;
        },
    },
    missing(locale, key) {
        // return import.meta.env.MODE === 'development' ? key : key.split('.').pop();
        return key.split('.').pop();
    },
    messages,
});

export const $dt = (date, options = { dateStyle: 'medium', timeStyle: 'medium' }) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return Intl.DateTimeFormat(i18n.locale, options).format(date);
};

export const $rdt = (d1, d2 = new Date()) => {
    const units = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: 24 * 60 * 60 * 1000 * 365 / 12,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000,
    };

    const rtf = new Intl.RelativeTimeFormat(i18n.locale, { numeric: 'auto' });

    if (!(d1 instanceof Date)) {
        d1 = new Date(d1);
    }
    if (!(d2 instanceof Date)) {
        d2 = new Date(d2);
    }
    const elapsed = d1 - d2;

    // "Math.abs" accounts for both "past" & "future" scenarios
    for (let i = 0; i < Object.keys(units).length; i += 1) {
        const u = Object.keys(units)[i];
        if (Math.abs(elapsed) > units[u] || u === 'second') {
            return rtf.format(Math.round(elapsed / units[u]), u);
        }
    }
    return '';
};

function setI18nLanguage(lang) {
    i18n.locale = lang;
    document.querySelector('html').setAttribute('lang', lang);
    localeChanged();
    localize(i18n.locale);
    return lang;
}

export function loadLanguageAsync(lang, filename) {
    try {
        // If the language hasn't been loaded yet
        const currentLang = lang || sessionStorage.getItem('websiteLanguage') || window.LBXFrontendData?.locale || 'en';
        sessionStorage.setItem('websiteLanguage', currentLang);
        const fullFilename = `${filename}.${currentLang}`;
        return import(`../../../../../config/locales/${fullFilename}.yml`).then(
            loadedMessages => {
                messages[currentLang] = {
                    ...(messages[currentLang] || {}),
                    ...loadedMessages.default[currentLang],
                };
                i18n.setLocaleMessage(currentLang, messages[currentLang]);
                return setI18nLanguage(currentLang);
            },
        );
    } catch (error) {
        console.error('loadLanguageAsync', error);
    }
    return null;
}

export default i18n;
