<template>
  <validation-provider
    v-bind="$attrs"
    ref="originalProvider"
    v-slot="originalSlotData"
    :mode="modeComputed"
  >
    <slot v-bind="{...originalSlotData, ...customSlotData}" />
  </validation-provider>
</template>

<script>
import {
    ref, computed, watch, onMounted,
} from 'vue';

export default {
    props: {
        mode: {
            type: String,
            default: 'customEager',
        },
    },
    setup(props) {
        const silentValid = ref(undefined);

        const customEager = ({ errors, flags }) => {
            if (flags.touched) {
                return {
                    on: ['input', 'change'],
                };
            }
            if (errors.length) {
                return {
                    on: ['input', 'change'],
                };
            }

            return {
                on: ['change', 'blur'],
            };
        };

        const modeComputed = computed(() => {
            if (props.mode === 'customEager') {
                return customEager;
            }
            return props.mode;
        });

        const originalProvider = ref(null);

        const customSlotData = computed(() => {
            if (!originalProvider.value) {
                return {};
            }
            let state = null;
            if (originalProvider.value.flags.validated) {
                state = originalProvider.value.flags.passed;
            }
            return {
                state,
                silentState: originalProvider.value.flags.valid,
                silentValid,
            };
        });

        const validateSilent = async () => {
            silentValid.value = (await originalProvider.value.validateSilent()).valid;
        };
        onMounted(async () => {
            validateSilent();
            watch(() => originalProvider.value.value, async () => {
                validateSilent();
            });
        });

        return {
            originalProvider,
            customSlotData,
            modeComputed,
            silentValid,
        };
    },
};
</script>
