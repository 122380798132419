---
en:
  CL:
    detail:
      heading: Customizing product listing for category
      info: You can pin, move or hide results for this product listing
    newQuery:
      cancel: Cancel
      creatingNewQuery: Creating new customization
      customizeResultsRanking: Customize results ranking
      customizeWhatUserSees: Search term which you want customize results for
      errors:
        blank_query: Error creating query - blank query
        default: Error creating query
        invalid_category_attribute: Error creating query - invalid category attribute
        invalid_item_type: Error creating query - invalid item type
      newQuery: Customize new product listing
      searchQuery: Category title
      searchQueryType: Type
    queries:
      blankSlateTitle: There are no product listing customizations at the moment
      deleteQuestion: Do you want to delete product listing customization?
      deleteSuccess: Product listing customization was deleted
      headerQuery: Category title and validity
      headerRules: Customizations
      search:
        prompt: Search in category titles
      searchEmpty: There are no active customizations matching "{query}"
  QC:
    changeModePopup:
      cancel: Continue editing
      close: Close this window
      editedInfo: You have pending unsaved changes in the current mode. If you switch mode now, these changes will be lost. If you want to keep them, press "Continue editing" and save pending changes.
      modes:
        advanced:
          button: Switch to advanced mode
          text: You are about to switch to advanced mode of query customization, where you can adjust settings individually for autocomplete and search.
        simple:
          button: Switch to single mode
          info: If you decide to switch again to advanced mode from simple mode later on, settings from that future simple mode will be used instead of what you have setup now in current advanced mode.
          settings: Please choose which settings should be used in the simple mode?
          source:
            autocomplete: Autocomplete
            search: Search
          text: If you switch to simple mode, you'll be managing settings for autocomplete and search together. Now you need to decide which settings should be transferred into the simple mode.
      question: Do you really want to change the customizations mode?
    confirmPopup:
      cancel: Cancel
    detail:
      addNewHiddenResult: Hide Particular Result
      addNewResult: Add New Result
      customizedResults: Customized Results
      customizedResultsEmpty: No customized Results
      discardChanges: Discard changes
      errors:
        active_from_must_be_a_date: Active from must be a date
        active_to_must_be_a_date: Active to must be a date
        cannot_block_and_pin_item_for_one_query_at_the_same_time: Can't block and pin item for one query at the same time
        chosen_position_for_this_query_is_already_occupied_in_selected_active_range: Chosen position for this query is already occupied in selected active range
        default: Something went wrong
        item_url_cannot_be_empty: Item URL cannot be empty
        not_found: 404 - query not found
        position_must_be_number_greater_than_0: Position must be number greater than 0
        query_cannot_be_empty: Query cannot be empty
        results_position_cannot_be_empty: Results position cannot be empty
        this_item_is_already_blocked_for_this_query_in_selected_active_range: This item is already blocked for this query in selected active range
        this_item_is_already_set_for_this_query_in_selected_active_range: This item is already set for this query in selected active range
        type_cannot_be_empty: Type cannot be empty
      filter:
        autocomplete: Autocomplete
        search: Search
      heading: Customizing results for query
      hiddenResults: Hidden Results
      hiddenResultsEmpty: No hidden Results
      modeChangeSuccess: Mode changed
      modes:
        advanced:
          info: You are in <strong>advanced mode</strong> where you are customizing results for autocomplete and search <strong>separately</strong>. If you want to customize the query results for Autocomplete and Search together you need to <strong>switch to single mode</strong>.
          switch: Switch to advanced mode
        simple:
          info: You are in <strong>simple mode</strong> that customizes the query results for Autocomplete and Search <strong>together</strong>. If you want to customize results for autocomplete and search <strong>separately</strong> you need to <strong>switch to advanced mode</strong>.
          switch: Switch to single mode
      positionInputTooltip: Confirm pin by pressing enter
      saveChanges: Save changes
      saveSuccess: Changes have been saved
      savingInProgress: Saving changes...
    discardPopup:
      cancel: Continue editing
      close: Continue editing
      discard: Discard pending changes
      question: Do you really want to <br> discard all pending changes?
      text: You are about to discard all unsaved changes you have made. <br> You will not be able to get back to them later.
    editPopup:
      acEmpty: No results found
      acItems: Results
      always: Always
      autocomplete: Autocomplete
      availability: Availability
      cancel: Cancel
      createHiddenQueryResult: Create hidden query result
      createQueryResult: Create query result
      everywhere: Everywhere
      onlyIfAvailable: Only if available
      placeholders:
        activeFrom: no start date set
        activeTo: no end date set
        url: Select result to pin
        urlHide: Select result to hide
      placement: Placement
      position: Position
      queryResult: Result
      saveCustomised: Save result settings
      saveHidden: Save hidden result settings
      search: Search
      timeFrame: Time frame
      unlockUrl: Change URL
      updateHiddenQueryResult: Update hidden result
      updateQueryResult: Update result
      whereToHide: Where to hide
    hiddenResult:
      delete: Delete
      edit: Edit
      from: From
      hiddenInAll: Hidden in All
      until: Until
    newQuery:
      cancel: Cancel
      creatingNewQuery: Creating new query
      customizeResultsRanking: Customize results ranking
      customizeWhatUserSees: Search term which you want customize results for
      errors:
        blank_query: Error creating query - blank query
        default: Error creating query
        invalid_item_type: Error creating query - invalid item type
      newQuery: Customize new query
      searchQuery: Search query
      searchQueryType: Type
    overlapInfo: Rules are overlaping. Do you want to automatically move old rules down by 1 position?
    queries:
      blankSlateTitle: There are no query customizations at the moment
      deleteConfirm: Delete
      deleteQuestion: Do you want to delete query customization?
      deleteSuccess: Query customization was deleted
      errors:
        loading: Error loading queries
        not_found: Delete failed - query not found
      headerQuery: Customized queries and validity
      headerRules: Customizations
      loading: Loading queries
      search:
        cancel: Cancel
        prompt: Search within queries
        search: Search
      searchEmpty: There are no active query customizations matching "{query}"
    queriesItem:
      blockedResults: "{n} blocked result | {n} blocked results"
      delete: Delete
      edit: Edit
      inAllResuls: in Autocomplete and Search
      inAutocompleteResuls: in Autocomplete
      inSearchResuls: in Search
      pinnedResults: "{n} pinned result | {n} pinned results"
      type: type
    result:
      delete: Delete
      edit: Edit
      hide: Hide
      notAvailable: Not available item
      notAvailableTitle: Current availability of the item is 0.
      notExisting: Not existing item
      notExistingTitle: Item is not present in catalog.
      pin: Pin
      pinned: Pinned
      until: Until
    resultGroup:
      collapse: Collapse future pinned results for {n} position
      expand: "+ {n} more rule planned in further future | + {n} more rules planned in further future"
      titleNotAvailable: "(title is not available)"
    unloadConfirm: Rules are not saved. Are you sure you want to leave?
  TIC:
    backToTypes: Back to type selection
    customizationIntroHtml: You can customize the display of TOP items for the selected type using various rules (by changing the order, hiding results, adding new ones...).
    detail:
      heading: Customizing of TOP items for type
    types:
      article: Article
      brand: Brand
      category: Category
      item: Product
      product: Product
      products: Producs
      query: Query
    typesHeading: 'Select type of results for customization:'
    typesIntroHtml: Select <strong>type of results for customization of TOP items</strong>, you can set specific rules in next step.
  VS:
    abTest: Run in AB test mode
    abTestTitle: AB test
    buttonOff: Disable
    collectorNote: Note that you have to update collector/integration to collect correct _SearchVariant and send events to GA.
    config:
      customCss: Custom CSS
      inputSelector: Search Input selector
      listeningTime: Listening Time (ms)
      placeholderSelector: Voice application display selector
      silenceTime: Silence Time (ms)
    contactButton: Contact support team
    contactText: If you don't know how to do it, simply <b>contact our support team</b> for help.
    disableQuestion: Do you really want to disable voice search?
    disableSuccess: Voice search disabled
    disableVoiceSearch: Disable voice search
    enable: Enable
    enableSuccess: Voice search enabled
    enableTitle: Enable voice search
    example: for example here.
    ga: Google analytics tracking code
    language: Preferred voice search language
    marginInfo: "<b>Margin</b> style is similar to padding, but it defines the outer offset of the element. By using a negative number, it is possible to move the selected element by a specific value to the desired side."
    moreInfo: You can find out more about CSS styles
    needHelp: Do you need help?
    notSaved: Not saved
    paddingInfo: "<b>Padding</b> style, or padding-side (left, right,top, bottom) is a numerical value which determines the internal offset of the element."
    popup:
      check: Review changes
      close: Close window
      publish: Save and publish changes
      text: We recommend first <b>verifying all changes in the test preview EDGE configuration</b> before each publication into real production.
      title: Are you sure?
    previewInfo: Before publishing a draft to production, <b>always verify the functionality of the changes</b> made on a computer and mobile using the EDGE configuration preview. After clicking the button, you can access previews of the changes thanks to a special URL that you <b>can share between devices</b>. Changes made will not be reflected in production.
    published: Published
    rewriteToDefault: Rewrite to default values
    save: Save (Ctrl/⌘ + S)
    saveCheck: Check if you do not have blocked opening of new windows in your browser settings.
    saveEdgePreview: Save & preview EDGE configuration
    savePreview: Save & preview configuration
    savePublish: Save & publish
    savedSuccess: Saved
    selectorInfo: "<b>.lb-voice-search-icon</b> is a selector for microphone icon in the search field, <b>.lb-voice-search-input</b> is a selector for search field."
    setLanguage: Language setting for voice search
    setSelector: Selector configuration
    settingLanguage: You set the interface and voice recognition language together
    template:
      add: "+ Custom template"
      item: Template
      name: Name
      remove: "- Remove"
      removeConfirmation: Are you sure you want to remove this custom template block?
      title: Custom template snippets
    timer: Timing setup
    title: Voice search setup
    translations:
      allowMic: Text to enable microphone
      close: Close voice search
      disablediconTitle: Description of icon without access to the microphone
      header: Title in the displayed interface
      iconTitle: Description of icon with access to the microphone
      listening: Text while listening
      listeningInfo: Additional text while listening
      recognitionError: Voice recognition error text
      recognitionErrorInfo: Voice recognition error information
      recognizing: Text while recognizing
      recognizingInfo: Additional text while recognizing
      stop: Stop voice search
      title: Translations
      tryAgain: Start voice search again
    turnedOf: Voice search is <b>disabled</b>. If it has already been launched in the past, it will <b>launch with its last settings</b>.
    turnedOn: Voice search is <b>running</b>. If you decide to turn it off, the next time you start it, <b>it will restore its current settings</b>.
    version:
      draft: Draft version
      production: Production version
  analyticsSessions:
    documentation: For more info see <a href='https://live.luigisbox.com/search_analytics.html' target="_blank">documentation<i class='icon-external-link'</i></a>
    event:
      detail: Detail
      missing: Event is missing
      missing_click: Session is missing valid click event
      missing_conversion: Session is missing valid conversion event
      ok: Event is okay
      type:
        autocomplete: Autocomplete event
        click: Click event
        conversion: Conversion event
        pageview: Pageview event
        recommender: Recommender event
        search: Search event
        transaction: Transaction event
    header:
      autocomplete: Autocomplete
      events: Events
      recommender: Recommender
      search: Search
      started_at: Started at
      user_id: User ID
    notifications:
      No session found: No session found :(
      Request timeout while finding session: Request timeout :(
      detail:
        loading: Loading event detail
      error: Something went wrong :(
      loading: Loading sessions
    popup:
      cancel: Close preview
      close: Close window
    search:
      placeholder: Find events by user ID
    subtitle: Sample of events in progress
    title: Visits
  app:
    interfaceLanguage: Interface language
    internalOnly: Internal only
    internalPlus: Internal+
    internalPlusHelp: Available to internal users and a few clients.
  authError:
    title: "Authorization Failed! 🔐"
  bannerCampaigns:
    addCampaign: Add banner campaign
    delete: Delete
    edit: Edit
    foundNothing: There are no banner campaigns found
    globalCampaign: Global campaign
    introBanner: Create your campaign by placing banners
    introBannerMiddle: with any link to defined locations
    introBannerPart2: in autocomplete and search results for a specific (or every) query you choose.
    item:
      applyToAllCategories: Apply to all categories
      applyToAllQueries: Apply to all queries
      autocomplete: Autocomplete
      brandList: List of brands
      campaignBanners: Banners in campaign
      cancel: Cancel campaign
      categoryPlaceholder: Choose category
      chooseCategory: Banner campaign categories
      downloadTemplates: Templates for preparation
      footer: Page footer
      list: Product in a list
      popupRequiredPlace: For this placement, the banner is required for all devices (desktop computers and mobile devices).
      properties: Main data
      queries: Queries
      queryTitle: If you add more queries, separate them by comma. Case and diacritics are ignored
      requiredPlace: This placement is required for both desktop computers and mobile devices.
      save: Save campaign
      searchCategories: Search for categories
      searchResults: Search results
      sidePanel: Side panel
      siteHeader: Site header
      targetUrl: Target Url
      targetUrlTitle: This is the url address where you will be redirected from banners
      topProduct: Top product
    notIntegratedInfo: It appears that banner&nbsp;<b>campaigns may require additional integration</b>&nbsp;on your side. You can find more information in the&nbsp;<a href="https://docs.luigisbox.com/#banner-campaigns" target="_blank" class="bc-wrapper--text-underline">documentation.</a>
    notifications:
      canceling: Canceling campaign
      creating: Creating campaign
      default: Unknown error occured
      deleting: Deleting campaign...
      duplicitCampaignPlaceholder: Same placeholder used in another campaign using the same query
      duplicitCampaignPlaceholderPlp: Same placeholder used in another campaign using the same category
      emptyBanners: No banners set
      emptyCampaignCategories: No category selected
      emptyCampaignQueries: No queries set
      emptyCampaignTargetUrl: No target URL set
      error: Failed to create a campaign
      invalidBannerFormatAutocomplete: Autocomplete banner must have both desktop URL and mobile URL set
      invalidBannerFormatSearch: Banner in list of products must have both desktop URL and mobile URL set
      invalidBannerUrlContent: Could not find image content in provided banner URL
      invalidBannerUrlProtocol: Banner URLs must use https protocol
      invalidCampaignTargetUrl: Target URL must use https protocol
      invalidCategoryAttribute: Could not find attribute '{value}' in your category structure
      invalidCategoryAttributeValue: Value '{value}' can not be used. Please use a value with exactly one member.
      invalidCategoryPath: Could not determine 'Category path'
      loading: Loading campaigns...
      saving: Saving campaign
    onlyHero: Available only for hero and heromobile layouts in Autocomplete
    popup:
      bannerListTitle: Banner within <b>List of brands</b> in autocomplete
      closeWindow: Close window
      computer: Computer devices
      computerBig: 1024x160px (JPG, 2048x320, max 600kb)
      computerDescBrands: 310x240px (JPG, 620x480, max 600kb)
      computerDescList: 340x490px (JPG, 680x980, max 600kb)
      computerDescSidePanel: 240x280px (JPG, 480x460, max 600kb)
      computerDescTop: 240x450px (JPG, 480x900, max 600kb)
      footerTitle: Banner within <b>Footer</b> in search results
      header: Table of positions and dimensions
      headerTitle: Banner within <b>Header</b> in search results
      listTitle: Banner within <b>Product in the list</b> in search results
      mobile: Mobile devices
      mobileDescBig: 420x240px (JPG, 840x480, max 600kb)
      mobileDescMedium: 340x490px (JPG, 680x980, max 600kb)
      sidePanelTitle: Banner within <b>Side panel</b> in search results
      topProductTitle: Banner within <b>TOP product</b> in autocomplete
    positionsTable: the table of positions
    runningCampaigns: Running campaigns
    searchQueries: Search queries
    urlExample: 'Example of correct URL address: <b>https://example.com/.../banner_name.jpg</b>'
  bulkCvsUpload:
    addList: Append to current list
    buttonText: Upload list
    downloadList: Download current list
    replaceList: Upload new list
  bulkUploadPopup:
    cancelButton: Cancel
    close: Close
    file:
      items: 'Items:'
      name: 'File name:'
    filepond:
      fileValidateTypeLabelExpectedTypes: Upload *.csv file
      labelIdle: Drag&drop or <span class="filepond--label-action">add list</span> in *.csv format
    previewLimitedInfo: File preview is limited to {rows} items.
    saveButton: Apply and save list into catalog
    saveButtonWorking: Saving list...
    structureButton: What should my *.csv look like?
    synonyms:
      add:
        okMessage: The new list has been successfully uploaded.
        structureInfo: "<h3>How should my csv file look:</h3><p>Create a table in your favorite spreadsheet editor, which looks like the example shown below and save it as a csv (comma separated values file). Do not use header row.</p><p><strong>Table example:</strong></p><table><tr><td>mobile phone</td><td>cell phone</td><td>cellular phone</td></tr><tr><td>television</td><td>tv</td><td>telly</td></tr><tr><td>air condition</td><td>a/c</td><td></td></tr></table>"
        text: "<h3>Add to the existing list</h3><p>New synonyms will be <strong>added to the existing list</strong>.</p><p>However, keep in mind that an entry from the <strong>new list</strong> for a word which is already present in the existing list will <strong>overwrite</strong> what was stored for that word."
      headers: word;synonym
      replace:
        okMessage: The new list has been successfully uploaded.
        structureInfo: "<h3>How should my csv file look:</h3><p>Create a table in your favorite spreadsheet editor, which looks like the example shown below and save it as a csv (comma separated values file). Do not use header row.</p><p><strong>Table example:</strong></p><table><tr><td>mobile phone</td><td>cell phone</td><td>cellular phone</td></tr><tr><td>television</td><td>tv</td><td>telly</td></tr><tr><td>air condition</td><td>a/c</td><td></td></tr></table>"
        text: <h3>Replace existing list</h3><p>By uploading this list, you will <strong>replace</strong> the existing one.<br><span class="warning-text">The current list will be discarded.</span></p>
    uploadButton: Upload new list
  clipboardCopy:
    info: Copied to clipboard!
    text: Copy to clipboard
  dataQuality:
    catalog:
      dataBreakdown: See data breakdown
      filter: Filter attributes
      notAttributesFilter: No attributes found matching current filter
    dataQualityCatalogTitle: Catalog attributes
    dataQualityCheckTitle: Data quality checks
    dataQualityMissingItemsTitle: Missing items
    item:
      copyUrl: Copy URL
      copyUrlInfo: URL copied!
      copyUrls: Copy All URLs
      copyUrlsInfo: All URLs copied!
      showInApi: Show detail
      showLess: Hide details
      showMore: Show details
      violationsFalse: OK
      violationsTrue: Error
    lastRun: Data quality check ran at
    missingItems:
      clearMissingItems: Delete stats
      clearMissingItemsConfirm: This will delete stats of all the reported missing items and it will be collected from scratch. This cannot be undone. Are you sure?
      clearMissingItemsSuccess: All missing items stats were successfully deleted.
      context:
        recommender: Recommender
      count: Count
      first_reported_at: First reported
      help: "<p>Our services are constantly looking for items from your Web that are missing in our catalog. Here you can seen a list of URLs that are present on your Web, but are missing in catalog.</p><p>When a missing item is added to your feed or is sent through API, it will be removed from this list within 24 hours.</p>"
      last_reported_at: Last reported
      showVerifiedOnly: Show only items missing more than 24 hours
      source: Source
      url: URL
    noData: There is no data yet.
    pairedItems:
      percentage: Paired items
      title: Paired items in catalog
      type: Item type
    run: Run data quality check
    running: Data quality check is running
    subTitle: for better search results
    title: Data quality checks
  emergencyDeactivation:
    active: Service is active
    helpText: You can turn off our services in case of technical problems. Shutting down will cause temporary deactivation. You can restart them at any time.
    interested: You are interested?
    interestedLink: https://www.luigisbox.com/contact/
    notActive: Service is deactivated
    notAvailable: You are not using this service at the moment.
    notAvailableNA: N/A
    notifications:
      activated: Lugi's Box {0} was activated, use an incognito window to check.
      activatedSoon: Lugi's Box {0} was activated, but it will take some time for the changes to take effect. Use an incognito window to check.
      deactivated: Lugi's Box {0} was deactivated, use an incognito window to check.
      deactivatedSoon: Lugi's Box {0} was deactivated, but it will take some time for the changes to take effect. Use an incognito window to check.
      error: Something went wrong :(
      failed: We're sorry current request has not been processed, please try again later.
      leave: Are you sure you want to leave this site? When you leave, you won't be able to see the actual state of request processing.
      loading: Loading settings
      requestConfirmed: Your request has been registered, but we need some time to process it.
    services:
      custom:
        name: LBX script
      recommender:
        name: Recommender
      search:
        name: Search + Autocomplete
    title: Emergency deactivation
    updatedAt: Last change {updatedAt}
  errors:
    default: Something went wrong
    invalid: Some data are not valid
    tryAgainLater: Please try again in a few minutes
    unableToLoadData: Couldn’t load data
  feedsHistory:
    header:
      error: Error
      feed: Feed
      schedule: Schedule
      started: Started
      status: Status
    item:
      failed: Failed
      manually: Manually
      queued: Queued
      running: Running
      scheduled: Scheduled
      succeeded: Success
    loadMore: Load more
    notifications:
      loading: Loading feeds history runs
      not_exist: This job does not exist.
      not_found: History feeds runs not found.
    subtitle:
      done: Done feeds
      running: Running feeds
    title: Feeds history
  feedsPlanning:
    actualPlan: Based on your current plan, the feed will run <b>{feedsPerDay} times a day</b>. Feed will next run at <b>{feedsDailySchedule}</b>
    calendar: Set up the plan manually
    cancelled: Cancelled
    cron: Set up the plan with cron
    currentLimitation: Your actual limit is {x} feed runs a day
    item:
      buttonConfirm: Confirm changes
      buttonError: Discard changes
      calendar:
        setHours: Set hours
        setMinutes: Set minutes
        tips:
          text: You can choose multiple options at once.
          title: Tips
      cron:
        dayOfTheMonth: Day of month
        dayOfTheWeek: Day of week
        hour: Hour
        minute: Minute
        month: Month
      help:
        anyValue: Any value
        title: Help
        valueRange: Value range
        valueSeparator: Value separator
        valueStep: Interval (e.g. */2 - every 2 hours)
      title: Set cron expression
    lastRun: Last run of feed was at {feedsLastRunAt}
    limitExceeded: Your actual limit exceeds {x} feeds plannings in one day, aborting!
    notSaved: Cannot be saved!
    run: You can run feeds manually <b>only once in 24 hours</b>.
    runFeed:
      alreadyRunning: Job is already running
      error: Job not found
      manuallyRunLimit: You can run feeds manually only once in 24 hours
      now: Run feed now
      runAgainTime: You will be able to run the feed again on {runnableAt}
      success: Job is set to run momentarily
    savedSuccess: Successfully saved
    scheduleNotValid: Schedule is not valid, aborting!
    title: Feeds planning
  filepond:
    fileValidateTypeLabelExpectedTypes: Expects {allButLastType} or {lastType}
    labelButtonAbortItemLoad: Abort
    labelButtonAbortItemProcessing: Cancel
    labelButtonProcessItem: Upload
    labelButtonRemoveItem: Remove
    labelButtonRetryItemLoad: Retry
    labelButtonRetryItemProcessing: Retry
    labelButtonUndoItemProcessing: Undo
    labelFileLoadError: Error during load
    labelFileLoading: Loading
    labelFileProcessing: Uploading
    labelFileProcessingAborted: Upload cancelled
    labelFileProcessingComplete: Upload complete
    labelFileProcessingError: Error during upload
    labelFileProcessingRevertError: Error during revert
    labelFileRemoveError: Error during remove
    labelFileSizeNotAvailable: Size not available
    labelFileTypeNotAllowed: File of invalid type
    labelFileWaitingForSize: Waiting for size
    labelIdle: Drag & Drop your files or <span class="filepond--label-action"> Browse </span>
    labelInvalidField: Field contains invalid files
    labelTapToCancel: tap to cancel
    labelTapToRetry: tap to retry
    labelTapToUndo: tap to undo
  hmacError:
    button: Back to Homepage
    info: You do not have permission to access this page.
    showMoreDetail: Show more error details
    title: "HMAC Authorization Failed! 🔐"
  loader:
    firstMessage: It may take a while...
    secondMessage: You look great today...
    staticMessage: We are working hard to analyze your data.
    thirdMessage: You can check the weather...
  menu:
    categories:
      activity: Activities
      advancedSettings: Advanced settings
      analysis: Analysis
      dashboards: Dashboards
      generalHelp: Help
      generalSettings: General settings
      improvements: Improvements
      info: Info
      myAccount: My account
      settings: Settings
    noSitesFound: No sites found
    reloadSites: Reload sites
    searchSites: Quick search sites
    sections:
      abTests:
        _sectionTitle: A/B Tests & Experiments
        abTesting: A/B Tests & Experiments
      catalog:
        _sectionTitle: Catalog
        boosting: Item boosting
        boostingRules: Term boosting
        catalogAttributes: Catalog attributes
        catalogBrowser: Catalog browser
        dataQualityChecks: Data quality checks
        feedsHistory: Feeds history
        feedsPlanning: Feeds planning
        keywords: Custom keywords
        lemmatizerOverrides: Lemmatizer overrides
        missingItems: Missing items
        synonymRecommendations: Synonyms recommendations
        synonyms: Synonyms
      configurations:
        _sectionTitle: Configurations
        allConfigurations: All configurations in project
        siteConfiguration: Site configuration
      dashboards:
        _sectionTitle: Overview
        defaultDashboard: Dashboard
      generalHelp:
        _sectionTitle: Help
        contactUs: Contact us
        documentation: API documentation
        redesign: Application interface redesign
        siteManagement: Site management
      products:
        _sectionTitle: Product listing
        facetCustomization: Facets customization
        merchandising: Merchandising
        productListingAnalysis: Product listing analytics
        setupBannersPlp: Campaigns in product listing
      recommendations:
        _sectionTitle: Recommendations
        bundles: Bundles
        cartValue: Cart Value
        recommendationAnalysis: Recommendations analysis
        recommendationCustomization: Recommendations customization
        recommendationDetail: Recommendation detail
        recommendationPreview: Recommendations preview
        recommendationRules: Recommenders rules
        recommendationSiteStats: Stats
        setupRecommenders: Recommendation setup
        setupRecommendersNew: Recommenders setup
      search:
        _sectionTitle: Search
        autocomplete: Autocomplete
        cartValue: Cart Value
        categoriesAndFilters: Categories & Filters
        challenges: Challenges
        descriptionAnalysis: Content analysis
        exitRate: Exit rate
        fixit: Redirects (Fixit)
        navigationAnalytics: Navigation analytics
        overview: Overview
        queryCustomization: Search results customization
        ranking: Relevance and ranking
        rewriteQuery: Rewrite query
        searchesWithLowConversions: Searches with low conversions
        searchesWithoutClicks: Searches without clicks
        searchesWithoutResults: Searches without results
        selfSetupAutocomplete: Autocomplete setup
        selfSetupSearch: Search setup
        setupAutocomplete: Autocomplete setup
        setupBanners: Campaigns in search
        setupSearch: Search setup
        setupVoice: Voice Search setup
        siteStats: Stats
        synonyms: Synonyms
        topItemsCustomization: Top Items customization
        topQueriesCustomization: Search Suggestions customization
        trendings: Trendings
      settings:
        _sectionTitle: General settings
        apiKeys: API keys
        auditLog: Audit log
        emergencyDeactivation: Emergency deactivation
        exports: Exports
        globalLemmatizerOverrides: Global lemmatiser overrides
        indirectConversionsReport: Indirect conversions analysis
        integrationSettings: Integration settings
        invoices: Invoices
        ipBlocking: IP addresses blocking
        liveSessionExplorer: Live session explorer
        paymentsAndPlans: Billing
        scripts: Scripts
        setupGlobalInit: Global initialization settings
        setupGlobalSettings: Global configuration settings
        setupLbxScript: Custom LBX script
        shoptetSettings: Shoptet integration settings
        unitAnalysis: Spent units stats
        userManagement: User management
        userProfile: Profile
    tags:
      beta: BETA
      new: NEW
    tooltips:
      backToMenu: Back to menu
      deleteDashboard: Delete Dashboard
      duplicateDashboard: Duplicate Dashboard
      renameDashboard: Rename Dashboard
      reorderDashboard: Drag to reorder dashboards
      switchMenuHiding: Switch menu hiding
    trialEnds: Your trial version ends <strong>in {count} day</strong>. | Your trial version ends <strong>in {count} days</strong>.
  notAuthorizedPage:
    button: Back to Home
    info: You don’t have permission to access this page.
    title: "You are not authorized! 🔐"
  pagination:
    loadMore: Load more
  redesignModal:
    close: Close window
    heading: "Please have patience 🙏"
    hide: Do not show this popup in the future
    htmlText: The Luigi's Box app is <strong>being redesigned</strong>.<br>That's why <strong>some features are coming in a new design</strong>, which can be confusing. However, we are doing everything we can to <strong>make this change as quick as possible</strong> so you can enjoy your new interface. Thank you!
    showMore: More about changes
  searchPreview:
    cancel: Cancel
    chooseTitle: Choose your item type and simply search
    debugMode: Enable debug mode
    displayJson: Display results as JSON
    editSorts: "+ Edit sorting options"
    filterTitle: Filtering
    identity: Identity
    intro: In catalog browser you can see the search results for different configurations in graphical or JSON view.
    noData: You have no catalog present in Luigi's Box yet. Please, check your data or simply contact our support team for help.
    placeholder: Search
    popup:
      addFilters: "+ Add more filters"
      cancel: Cancel
      close: Close
      confirm: Confirm selection
      inputTitle: Search and add one or more filters
      subTitle: All product attributes
      title: Add more filters
    save: Save changes
    settings:
      columnTitle: View settings
      inputPlaceholder: Search
      settingsHitFields: Choose parameters for search (hit_fields)
      showLess: Show less attributes
      showLessDebug: Show less debug attributes
      showMore: Show more attributes
      showMoreDebug: Show more debug attributes
      title: Settings
    title: Catalog browser
  setupRecommenders:
    add_rule: "+ Add new rule"
    disabled_html: This feature has been replaced by <a target="_blank" href="recommendations_customization">recommendations customization</a> and is only used for viewing of already existing rules. In the future, existing rules will be migrated to recommendations customization and reco rules feature will be abandoned.
    intro_html: You are setting <b>combinations of item categories and recommender results categories</b>, which must (or not) be displayed together. The created rules are required for the recommender and must always be followed. For example, if you decide to recommend items from the "guitar" category to items from the "strings" category, only items from the "strings" category will be displayed in the results.
    item:
      action: Choose action
      chooseId: Choose ID
      copy: Make copy
      copyAllText: Copy all from
      create_rule: Create rule for
      created_rule: Rule for
      discard_rule: Discard rule
      do_not_recommend_together: do not recommend together
      edit_rule: Edit rule
      from: product from
      overrides: overrides
      productsIn: If product is in
      recommend_together: recommend together
      recommend_together_text: Always choose broader and more general categories so that the rule doesn't overly limit the group of products that it can recommend.
      recommenderId: Choose recommender type
      remove_rule: Remove rule
      ruleId: with ID
      save_rule: Save rule
      selectCategory: Select category
      then: then
    notifications:
      actionForbidden: Not saved. Positive rules are temporarily disabled, please contact support team.
      actionMissing: Not saved. Choose action
      alreadyAssignedRule: Not saved. {values} is already present in rule with ID {ruleId}. Please edit that rule instead
      conditionValuesMissing: Not saved. Choose categories for product
      duplicitRule: Not saved. This rule is duplicit with rule with ID {ruleId}
      error: Recommender rules could not be loaded :(
      filterValuesMissing: Not saved. Choose categories for product
      incompatibleRule: Not saved. This rule is incompatible with rule with ID {ruleId}
      loading: Loading recommenders setup
      not_found: No recommender definitions found, please define them first
      recommenderIdMissing: Not saved. Choose recommender type
      saved: Recommenders setup saved
    popup:
      close: Close
      closeWindow: Close window
      discardRule: Discard changes
      header: Are you sure?
      removeRule: Remove this rule
      subtitleDiscard: Are you sure you want to discard all changes?
      subtitleRemove: Are you sure you want to remove this rule?
    title: Recommenders rules
    to_reco_settings: Recommender definitions settings
  siteStats:
    add: Add
    advanced: Advanced...
    apply: Apply
    breakdown: Breakdown
    cancel: Cancel
    chooseMetrics: Choose metrics
    clear: Clear
    customize: Customize
    date: Date
    dateRangePlaceholder: choose dates...
    exportCsv: Export (.csv)
    exportProjectXlsx: Export project (.xlsx)
    exportXlsx: Export (.xlsx)
    filterMetrics: filter metrics...
    loading: Loading
    metric: Metric
    overview: Overview
    pleaseSelectDate: Please select some date range or
    previousMonth: Previous month
    previousYear: Previous year
    remove: Remove
    resetFilters: Cancel filters
    selectNone: Select none
    showDataForThisMonth: show data for this month
    splitByDays: To days
    splitByMonths: To months
    splitByWeeks: To weeks
    thisMonth: This month
    thisYear: This year
    title: Stats
    unit: Unit
    units: Units
  sitesGroups:
    toggle: Manage sites
