import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import local from './local';
import session from './session';
import setup from './setup';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        local,
        session,
        appConfig,
        verticalMenu,
        setup,
        'app-ecommerce': ecommerceStoreModule,
    },
    strict: false,
});
