import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function notification(title, text, variant) {
    let iconToUse = 'CheckIcon';
    if (variant === 'danger') {
        iconToUse = 'XIcon';
    }
    if (variant === 'primary') {
        iconToUse = 'BellIcon';
    }
    if (variant === 'warning') {
        iconToUse = 'AlertCircleIcon';
    }

    window.LBXFrontend.$toast({
        component: ToastificationContent,
        props: {
            title,
            icon: iconToUse,
            text,
            variant,
        },
    },
    {
        position: 'top-center',
        type: variant,
    });
}
