/* eslint-disable vue/component-definition-name-casing */
import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';
import {
    BCard, BButton, BContainer, BRow, BFormRow, BFormGroup, BFormInput, BInputGroup,
    BInputGroupPrepend, BCol, BImg, BCardText, BForm, BInputGroupAppend, BDropdownItem,
    BDropdownDivider, BDropdown, BDropdownHeader, BFormCheckbox, VBTooltip, BTooltip,
    BAlert, BFormSpinbutton, VBPopover, BPopover, BFormRadio, BFormRadioGroup, BFormSelect,
    BSpinner, BFormTextarea, BBadge, BOverlay, BSidebar, BListGroup, BListGroupItem,
    BFormDatepicker, VBToggle,
} from 'bootstrap-vue';
import {
    ValidationProvider, ValidationObserver,
} from 'vee-validate';

import InfoTooltip from '@/components/InfoTooltip.vue';
import BButtonOverride from '@/components/BButtonOverride.vue';
import NumberInput from '@/components/NumberInput.vue';
import CustomValidationProvider from '@/views/forms/CustomValidationProvider.vue';
import Ripple from 'vue-ripple-directive';

Vue.directive('ripple', Ripple);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component('b-card', BCard);
Vue.component('b-card-text', BCardText);
Vue.component('b-button-original', BButton);
Vue.component('b-badge', BBadge);
Vue.component('b-button', BButtonOverride);
Vue.component('b-container', BContainer);
Vue.component('b-row', BRow);
Vue.component('b-form-row', BFormRow);
Vue.component('b-col', BCol);
Vue.component('b-img', BImg);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-form', BForm);
Vue.component('b-form-input', BFormInput);
Vue.component('b-number-input', NumberInput);
Vue.component('b-form-textarea', BFormTextarea);
Vue.component('b-input-group', BInputGroup);
Vue.component('b-input-group-prepend', BInputGroupPrepend);
Vue.component('b-list-group', BListGroup);
Vue.component('b-list-group-item', BListGroupItem);
Vue.component('b-input-group-append', BInputGroupAppend);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('b-dropdown', BDropdown);
Vue.component('b-dropdown-header', BDropdownHeader);
Vue.component('b-form-checkbox', BFormCheckbox);
Vue.component('b-form-select', BFormSelect);
Vue.component('b-tooltip', BTooltip);
Vue.component('b-alert', BAlert);
Vue.component('b-form-spinbutton', BFormSpinbutton);
Vue.component('b-popover', BPopover);
Vue.component('b-form-radio-group', BFormRadioGroup);
Vue.component('b-form-radio', BFormRadio);
Vue.component('b-sidebar', BSidebar);
Vue.component('b-spinner', BSpinner);
Vue.directive('b-tooltip', VBTooltip);
Vue.directive('b-popover', VBPopover);
Vue.directive('b-toggle', VBToggle);
Vue.component('b-form-datepicker', BFormDatepicker);

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('custom-validation-provider', CustomValidationProvider);

Vue.component('info-tooltip', InfoTooltip);
Vue.component('b-overlay', BOverlay);
