export const sendDataReadyEvent = () => {
    const getCookie = name => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return undefined;
    };

    const fields = ['email', 'phone', 'first', 'last', 'country'];
    const values = [];
    fields.forEach(field => {
        const cookieValue = getCookie(`lbx-dl-${field}`);
        if (cookieValue) {
            values.push(cookieValue);
        }
    });
    const user = {
        email: values[0],
        phone: values[1],
        first: values[2],
        last: values[3],
        country: values[4],
    };
    Object.keys(user).forEach(key => {
        if (!user[key]) {
            delete user[key];
        }
    });
    if (Object.keys(user).length) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'data_ready',
            user,
        });
    }
};
