import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import stream from 'rollup-plugin-node-polyfills/polyfills/stream';
import clonedeep from 'lodash.clonedeep';
import VueSanitize from 'vue-sanitize-directive';
import { dropdownKeyboardHelper } from '@/libs/directives';
import '@/libs/mockRequire';

import i18n, { loadLanguageAsync } from '@/libs/i18n';
import router from '../router';
import store from '../store';
import App from '../App.vue';

// Global Components
import '../global-components';

// 3rd party plugins
import '@axios';
// import '@/libs/acl';
// import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
// import '@/libs/sweet-alerts';
import '@/libs/vue-select';
// import '@/libs/tour';

// Axios Mock Adapter
// import '@/@fake-db/db';

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
import '@core/assets/fonts/feather/iconfont.css'; // For form-wizard

// import core styles
import '@core/scss/core.scss';

// import assets styles
import '@/assets/scss/style.scss';

window.stream = stream;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

Vue.directive('dropdownKeyboardHelper', dropdownKeyboardHelper);

// Vue 2.x
Vue.use(VueSanitize);

Vue.config.productionTip = false;

Vue.prototype.$window = window;
Vue.prototype.$cd = clonedeep;
Vue.prototype.$dummyText = text => {
    // eslint-disable-next-line no-console
    console.log('Using $dummyText', text);
    return text;
};
window.LBXFrontendRouter = router;
window.LBXFrontendStore = store;
store.dispatch('app/start');

window.LBXFrontend = new Vue({
    name: 'LBXFrontend',
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');

let lastDDopened = null;
window.LBXFrontend.$on('bv::dropdown::show', bvEvent => {
    lastDDopened = bvEvent.componentId;
});
window.LBXFrontend.$on('bv::dropdown::hide', bvEvent => {
    const ddToClose = bvEvent.componentId;
    if ((lastDDopened === 'lb-ranges-dropdown' || lastDDopened === 'lb-ranges-1-dropdown') && ddToClose === 'lb-datepicker-dropdown') {
        bvEvent.preventDefault();
        lastDDopened = null;
    }
});

loadLanguageAsync(window.LBXFrontendData?.locale, 'vue');
loadLanguageAsync(window.LBXFrontendData?.locale, 'signup');
loadLanguageAsync(window.LBXFrontendData?.locale, 'frontend');
