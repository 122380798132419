import Vue from 'vue';
// axios
import axios from 'axios';

import { $t } from '@/libs/i18n';
import notification from '@/views/components/notification';

import { useStore } from '@/libs/vuex';

const hmacKeyCache = {};

const hmacInterceptor = async config => {
    // bug in axios, data must be st
    config.data ||= {};

    config.headers ||= {};
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    config.headers['X-Date'] = new Date().toUTCString();

    const store = useStore();

    const trackerId = config.data?.trackerId
        // eslint-disable-next-line no-underscore-dangle
        || config.data?._trackerId
        || window.LBXFrontendData.siteData.trackingCode;

    const getApiKey = async () => {
        try {
            const { data } = await axiosInstance.get(`/sites/${trackerId}/onboarding/api_key`, {
                params: {
                    scope: config.data?.scope || null,
                },
            });
            return data.api_key?.length > 0 && data.api_key[0].key;
        } catch (error) {
            return null;
        }
    };

    if (!hmacKeyCache[trackerId]) {
        hmacKeyCache[trackerId] = await getApiKey();
    }

    delete config.data?.scope;
    delete config.data?._trackerId;

    const privateKey = hmacKeyCache[trackerId];

    // DEV demoshop DO NOT LEAVE THIS EVEN AS COMMENT IN PRODUCTION
    // const privateKey = 'xxx';

    if (!privateKey) {
        throw new Error('missing privateKey for HMAC auth');
    } else {
        store.commit('app/SET_RECOMMENDER_PRESENT', true);
    }

    config.url = config.url.replace('REPLACE_TRACKER_ID', trackerId).replace(/\/+/g, '/');

    const digest = async () => {
        let digestUrl = `${config.baseURL}${config.url}`.replace(/\/+/g, '/');
        digestUrl = new URL(digestUrl);
        digestUrl = digestUrl.pathname;

        const message = [
            config.method.toUpperCase(),
            config.headers['Content-Type'],
            config.headers['X-Date'],
            digestUrl,
        ].join('\n');

        const getUtf8Bytes = str => new Uint8Array(
            [...unescape(encodeURIComponent(str))].map(c => c.charCodeAt(0)),
        );

        const keyBytes = getUtf8Bytes(privateKey);
        const messageBytes = getUtf8Bytes(message);

        const cryptoKey = await crypto.subtle.importKey(
            'raw', keyBytes, { name: 'HMAC', hash: 'SHA-256' },
            true, ['sign'],
        );
        const sig = await crypto.subtle.sign('HMAC', cryptoKey, messageBytes);

        // to lowercase hexits
        [...new Uint8Array(sig)].map(b => b.toString(16).padStart(2, '0')).join('');

        // to base64
        return btoa(String.fromCharCode(...new Uint8Array(sig)));
    };

    const digested = await digest();

    config.headers.Authorization = `ApiAuth ${trackerId}:${digested}`;

    return config;
};

const axiosInstance = axios.create({
    // You can add your headers here
    // ================================
    // baseURL: 'https://app.luigisbox.com',
    // timeout: 1000,
    // headers: { 'Access-Control-Allow-Origin': 'https://app.luigisbox.com', 'Access-Control-Allow-Methods': 'GET, POST, PUT', 'Access-Control-Max-Age': '1800'},
});

axiosInstance.interceptors.request.use(config => {
    // this is used for FE testing
    config.headers['x-lbx-axios-api'] = '1';
    return config;
});

axiosInstance.interceptors.request.use(config => {
    if (
        [
            'post',
            'put',
            'patch',
            'delete',
        ].includes(config.method)
    ) {
        const tokenInput = document.querySelector('meta[name=csrf-token]');
        const tokenNameInput = document.querySelector('meta[name=csrf-param]');
        if (tokenInput && tokenNameInput) {
            const tokenValue = tokenInput.getAttribute('content');
            const tokenName = tokenNameInput.getAttribute('content');
            if (tokenValue && tokenName) {
                config.data = config.data || {};
                config.data[tokenName] = tokenValue;
            }
        }
    }
    return config;
}, error => Promise.reject(error));

const reccoAxiosInstance = axios.create({
    baseURL: `${import.meta.env.JS_EXPOSED_RECO_SETUP_ENDPOINT}/v1/recommender/`,
    withCredentials: false,
});

reccoAxiosInstance.interceptors.request.use(hmacInterceptor, error => Promise.reject(error));

const mockApi = (payload = {}) => new Promise((resolve, reject) => {
    if (import.meta.env.MODE !== 'development') {
        // eslint-disable-next-line no-console
        console.error('Warning: You are using $mockApi! Payload:', payload);
    }
    setTimeout(() => {
        if (payload.mockError === true) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ error: true });
        } else if (payload.mockError) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(mockError);
        } else if (payload.mockData) {
            resolve({ data: payload.mockData });
        } else {
            resolve({ ok: true });
        }
    }, payload.mockTime || 200);
});

const defaultHandleErrorOptions = {
    title: '_title', // set to null to disable
    fallbackMessage: '_default',
    prefix: '_errors',
    key: 'error_message', // key of data.response
    attribute: 'error_attribute', // key of data.response
    variant: 'danger',
};

const $handleError = (err, optionsParam = defaultHandleErrorOptions) => {
    const options = {
        ...defaultHandleErrorOptions,
        ...optionsParam,
    };

    let title = null;
    if (options.title) {
        title = $t(`${options.prefix}.${options.title}`);
    }

    let msg = $t(`${options.prefix}.${options.fallbackMessage}`);
    const msgFromResponse = err?.response?.data[options.key];
    const attrFromResponse = err?.response?.data[options.attribute];
    if (msgFromResponse) {
        const attr = attrFromResponse ? `_${attrFromResponse}` : '';
        msg = $t(`${options.prefix}.${msgFromResponse}${attr}`);
    }

    if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.error('[👨‍💻 DEV 👩‍💻] $handleError 👉 ', err);
    }

    notification(title, msg, options.variant);
};

export default {
    $axios: axiosInstance,
    $reccoAxios: reccoAxiosInstance,
    $handleError,
    $mockApi: payload => mockApi(payload),
    idToUrlMap({ siteId, type } = {}) {
        if (!siteId) {
            siteId = window.LBXFrontendData.siteData.trackingCode;
        }
        return axiosInstance.get(`/sites/${siteId}/id_to_url_map`, {
            params: {
                type,
            },
        });
    },
    integrationInfo(payload = { siteId: undefined }) {
        if (!payload.siteId) {
            payload.siteId = window.LBXFrontendData.siteData.trackingCode;
        }
        return axiosInstance.get(`/sites/${payload.siteId}/integration_info`);
    },
    autocompleteResults(payload) {
        const server = import.meta.env.JS_EXPOSED_LIVE_ENDPOINT;
        return axiosInstance.get(`${server}/autocomplete/v2?${payload}`);
    },
    searchResults(payload) {
        const server = import.meta.env.JS_EXPOSED_LIVE_ENDPOINT;
        return axiosInstance.get(`${server}/search?${payload}`);
    },
    topItems(payload) {
        const server = import.meta.env.JS_EXPOSED_LIVE_ENDPOINT;
        return axiosInstance.get(`${server}/v1/top_items?${payload}`);
    },
    getAttributeValues(payload = {
        siteId: undefined, facets: undefined, query: undefined, types: undefined,
    }) {
        return axiosInstance.get(`/sites/${payload.siteId}/catalog_search/find_attribute_values`, {
            params: {
                field: payload.facets,
                search_term: payload.query,
            },
        });
    },
    getSearchFacets(payload = {
        siteId: undefined, facets: undefined, query: undefined, types: undefined,
    }) {
        const server = import.meta.env.JS_EXPOSED_LIVE_ENDPOINT;
        const types = payload.query && payload.types ? payload.types : '';
        const queryPart = payload.query ? `&q=${payload.query}` : '';
        const SIZE = 100;
        return axiosInstance.get(`${server}/search?tracker_id=${payload.siteId}&facets=${payload.facets}:${SIZE}${queryPart}${types}&size=0`);
    },
    LuigiSelect(payload = { siteId: undefined, query: undefined, getAllAttributes: undefined }) {
        if (!payload.siteId) {
            payload.siteId = window.LBXFrontendData.siteData.trackingCode;
        }
        return axiosInstance.get(`/sites/${payload.siteId}/catalog_search/search`, {
            params: {
                query: payload.query.value,
                all_attributes: payload.getAllAttributes ? true : undefined,
            },
            headers: {
                Accept: 'application/json',
            },
        });
    },
    setLanguage(payload = { locale: undefined }) {
        return axiosInstance.patch('/sites/settings/profile/set_app_language', {
            user: {
                locale: payload.locale,
            },
        });
    },
    CF: {
        save(payload = { siteId: undefined, customization: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/facet_customization`, {
                category: payload.customization.category,
                filters: payload.customization.filters.map(f => ({
                    position: f.position,
                    filter_name: f.filterName,
                    attribute_name: f.attributeName,
                    visible_items: f.visibleCount,
                    number_of_all_items: f.allItemsCount,
                    without_limit: f.withoutLimit,
                    sort: f.sortItems,
                })),
                ignore_request_facets: payload.customization.ignore_request_facets,
                id: payload.customization.id,
            });
        },
        load(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/facet_customization`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        loadDetail(payload = { siteId: undefined, id: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/facet_customization/${payload.id}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        loadFilters(payload = { siteId: undefined, category: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/filters`, {
                headers: {
                    Accept: 'application/json',
                },
                params: {
                    category: payload.category,
                },
            });
        },
        getRecommendedFilters(payload = { siteId: undefined, category: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/facet_customization/recommended_filters`, {
                headers: {
                    Accept: 'application/json',
                },
                params: {
                    category: payload.category,
                },
            });
        },
        delete(payload = { siteId: undefined, customization: undefined }) {
            return axiosInstance.delete(`/sites/${payload.siteId}/facet_customization`, {
                data: {
                    id: payload.customization.id,
                    category: payload.customization.category,
                },
            });
        },
    },
    users: {
        getRoles() {
            return axiosInstance.get('/users/sign_up', {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        getInvitation() {
            return axiosInstance.get('/users/form', {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        registerInvitation(payload = { registerParams: undefined }) {
            const params = payload.registerParams;
            return axiosInstance.post('/users/form', {
                params,
            });
        },
        saveRole(role) {
            return axiosInstance.post('/users/roles', {
                role,
            });
        },
    },
    onboarding: {
        signup: {
            register(payload = { registerParams: undefined }) {
                const params = payload.registerParams;
                return axiosInstance.post('/users/sign_up', {
                    params,
                });
            },
            onboard(payload = { registerParams: undefined }) {
                const params = payload.registerParams;

                return axiosInstance.post('/users/onboarding', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
            },
            verify_email(payload = { token: undefined }) {
                const { token } = payload;
                return axiosInstance.patch('/users/confirmation', {
                    token,
                });
            },
            resend_email(payload = { email: undefined }) {
                return axiosInstance.post('/users/confirmation', {
                    user: {
                        email: payload.email,
                    },
                    commit: 'Resend confirmation instructions',
                });
            },
        },
        createProject: {
            load() {
                return axiosInstance.get('/project/create_project', {
                    headers: {
                        Accept: 'application/json',
                    },
                });
            },
            addProject(payload = { projectParams: undefined }) {
                const params = payload.projectParams;
                return axiosInstance.post('/project/create_project', {
                    params,
                });
            },
            checkIntegration(payload = { siteId: undefined }) {
                return axiosInstance.get(`/sites/${payload.siteId}/onboarding/validate_script`, {
                    headers: {
                        Accept: 'application/json',
                    },
                });
            },
            sendInstructions(payload = { siteId: undefined, email: undefined }) {
                return axiosInstance.post(`/sites/${payload.siteId}/onboarding/send_developer`, {
                    email: payload.email,
                });
            },
        },
        resetPassword(payload = { email: undefined }) {
            return axiosInstance.post('/users/password', {
                user: {
                    email: payload.email,
                },
                commit: 'Continue',
            });
        },
        confirmPassword(payload = { resetPasswordToken: undefined, password: undefined, passwordConfirmation: undefined }) {
            return axiosInstance.put('/users/password', {
                user: {
                    reset_password_token: payload.resetPasswordToken,
                    password: payload.password,
                    password_confirmation: payload.passwordConfirmation,
                },
                commit: 'Change my password',
            });
        },
        login(payload = { loginParams: undefined }) {
            const params = payload.loginParams;
            return axiosInstance.post('/users/sign_in', {
                user: {
                    email: params.email,
                    password: params.password,
                },
                commit: 'Log in',
            });
        },
        // loading all data for first screen
        loadProgress(payload = { siteId: undefined }) {
            return {
                codeVerified: false,
                codeIncluded: false,
                catalogIntegrated: false,
                currentProgress: 0,
            };
        },
        getTagManagerScriptUrl(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/onboarding/tag_manager_script_url`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        saveCatalogIntegration(payload = { siteId: undefined, params: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/integration/catalog_integration`, payload.params);
        },
        getApiKey(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/onboarding/api_key`);
        },
        generateApiKey(payload = { siteId: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/onboarding/api_key`);
        },
        finishIntegration(payload = { siteId: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/feeds/finish`);
        },
        feedsIntegration: {
            saveData(payload = { siteId: undefined, params: undefined }) {
                const finalParams = payload.params;
                return axiosInstance.post(`/sites/${payload.siteId}/feeds/urls`, {
                    ...finalParams,
                });
            },
            loadFeeds(payload = { siteId: undefined, hitFields: undefined }) {
                const hitFields = payload.hitFields ? `?hit_fields=${payload.hitFields}` : '';
                return axiosInstance.get(`/sites/${payload.siteId}/feeds${hitFields}`, {
                    headers: {
                        Accept: 'application/json',
                    },
                });
            },
            validateFeeds(payload = { siteId: undefined }) {
                return axiosInstance.post(`/sites/${payload.siteId}/feeds/validate`);
            },
            saveMapping(payload = { siteId: undefined, feed: undefined }) {
                return axiosInstance.post(`/sites/${payload.siteId}/feeds/mapping`, {
                    ...payload.feed,
                });
            },
            refreshAttributes(payload = { siteId: undefined, feedId: undefined }) {
                return axiosInstance.post(`/sites/${payload.siteId}/feeds/refresh_attributes/${payload.feedId}`);
            },
        },
    },
    sites: {
        loadSite(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/info`, {
                params: {
                    site_id: payload.siteId,
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        checkoffBackendIntegration(payload = { siteId: undefined, is_backend_integrated: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/checkoff_backend_integration`, {
                is_backend_integrated: payload.is_backend_integrated,
            });
        },
    },
    globalSettings: {
        enable: ({ trackerId }) => axiosInstance.patch(`/sites/${trackerId}/setup/global_settings/enable`, {
            has_managed_global_settings: true,
        }, {
            headers: {
                accept: 'application/json',
            },
        }),
        getConfig: ({ trackerId }) => axiosInstance.get(`/sites/${trackerId}/setup/global_settings/show_json`, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        saveConfig: ({
            trackerId, config, version, publish, preview,
        }) => {
            const transformedConfig = { ...config };
            Object.keys(transformedConfig).forEach(key => {
                if (typeof transformedConfig[key] === 'function') {
                    // transformedConfig[key] = transformedConfig[key].toString();
                    transformedConfig[key] = `REPLACE_STRING_QUOTE${transformedConfig[key].toString()}REPLACE_STRING_QUOTE`.replace(/\n/g, '');
                }
            });
            return axiosInstance.put(`/sites/${trackerId}/setup/global_settings`, {
                config: JSON.stringify({ settings: JSON.stringify(transformedConfig).replace(/"?REPLACE_STRING_QUOTE"?/g, '') }),
                version: JSON.stringify(version),
                publish: JSON.stringify(publish),
                create_preview_url: JSON.stringify(false),
                create_edge_url: JSON.stringify(!!preview),
            }, {
                params: {},
                headers: {
                    accept: 'application/json',
                },
            });
        },
    },
    autocomplete: {
        getConfig: ({ trackerId }) => axiosInstance.get(`/sites/${trackerId}/setup/autocomplete`, {
            data: {},
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        getProductionConfig: ({ trackerId }) => axiosInstance.get(`/sites/${trackerId}/setup/autocomplete/production_configuration`, {
            data: {},
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        saveConfig: ({
            trackerId, config, version, publish, preview, title, message,
        }) => axiosInstance.put(`/sites/${trackerId}/setup/autocomplete`, {
            config: JSON.stringify(config),
            version: JSON.stringify(version),
            publish: JSON.stringify(publish),
            create_preview_url: JSON.stringify(false),
            create_edge_url: JSON.stringify(!!preview),
            title,
            message,
            modified: title ? 'true' : undefined, // i dont even...
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        activate: ({ trackerId }) => axiosInstance.patch(`/sites/${trackerId}/setup/autocomplete/enable`, {
            has_managed_autocomplete: JSON.stringify(true),
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        disable: ({ trackerId }) => axiosInstance.patch(`/sites/${trackerId}/setup/autocomplete/disable`, {
            has_managed_autocomplete: JSON.stringify(false),
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        getColorScheme({ trackerId, color }) {
            return axiosInstance.get(`/sites/${trackerId}/setup/autocomplete/color_scheme`, {
                params: {
                    primary_color: color,
                },
            });
        },
    },
    recommendersCustomizations: {
        getSummary() {
            return reccoAxiosInstance.get('/pin/REPLACE_TRACKER_ID/summary', {
                data: {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        searchScopes(payload = { data: undefined, bodyParams: undefined }) {
            const requestParams = payload.data ? `?${payload.data}` : '';
            if (payload.bodyParams) {
                return reccoAxiosInstance.post(`/pin/REPLACE_TRACKER_ID/scopes${requestParams}`, {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                    criteria: [payload.bodyParams],
                });
            }
            return reccoAxiosInstance.post(`/pin/REPLACE_TRACKER_ID/scopes${requestParams}`, {
                // _trackerId: '179075-204259',
                scope: 'recommender-setup',
            });
        },
        savePins(payload = { data: undefined }) {
            return reccoAxiosInstance.post('/pin/REPLACE_TRACKER_ID/scope', {
                // _trackerId: '179075-204259',
                scope: 'recommender-setup',
                ...payload.data,
            });
        },
        getPinById(payload = { data: undefined }) {
            return reccoAxiosInstance.get(`/pin/REPLACE_TRACKER_ID/scope/${payload.data.scopeId}`, {
                data: {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        updatePinById(payload = { data: undefined, id: undefined }) {
            return reccoAxiosInstance.put(`/pin/REPLACE_TRACKER_ID/scope/${payload.id}`, {
                // _trackerId: '179075-204259',
                scope: 'recommender-setup',
                ...payload.data,
            });
        },
        deletePinById(payload = { id: undefined }) {
            return reccoAxiosInstance.delete(`/pin/REPLACE_TRACKER_ID/scope/${payload.id}`, {
                data: {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                },
            });
        },
    },
    recommenders: {
        enableRecommenders(payload = { trackerId: undefined }) {
            return axiosInstance.post(`/sites/${payload.trackerId}/onboarding/enable_recommenders`);
        },
        getRecoList: ({ trackerId }) => axiosInstance.get(`/sites/${trackerId}/setup/recommenders`, {
            data: {},
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        getCurrentConfig: ({ trackerId, id }) => axiosInstance.get(`/sites/${trackerId}/setup/recommenders/${id}`, {
            data: {},
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        getProductionConfig: ({ trackerId }) => axiosInstance.get(`/sites/${trackerId}/setup/recommenders/production_configuration`, {
            data: {},
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        saveConfig: ({
            trackerId, config, version, publish, preview, id,
        }) => axiosInstance.put(`/sites/${trackerId}/setup/recommenders/${id}`, {
            config: JSON.stringify(config),
            version: JSON.stringify(version),
            publish: JSON.stringify(publish),
            create_preview_url: JSON.stringify(false),
            create_edge_url: JSON.stringify(!!preview),
            uuid: id,
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        deleteRecommender: ({
            trackerId, name, id,
        }) => axiosInstance.delete(`/sites/${trackerId}/setup/recommenders/${id}`, {
            data: {
                id,
                name,
            },
        }),
        activateLbx: ({ trackerId }) => axiosInstance.patch(`/sites/${trackerId}/setup/recommenders/enable`, {
            has_managed_recommender: JSON.stringify(true),
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        disableLbx: ({ trackerId }) => axiosInstance.patch(`/sites/${trackerId}/setup/recommenders/disable`, {
            has_managed_recommender: JSON.stringify(false),
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        getColorScheme({ trackerId, color }) {
            return axiosInstance.get(`/sites/${trackerId}/setup/recommenders/color_scheme`, {
                params: {
                    primary_color: color,
                },
            });
        },
        generateNewId(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/setup/recommenders/generate_new_uuid`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        getFilters(payload = { siteId: undefined, paramsString: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/filters${payload.paramsString}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        loadTypes(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/types`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        // TODO odstranit vsetky 204259 je to len TEST
        loadGlobalSetup() {
            return reccoAxiosInstance.get('/setup/REPLACE_TRACKER_ID/global', {
                data: {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        saveGlobalSetup(payload = { data: undefined }) {
            return reccoAxiosInstance.post('/setup/REPLACE_TRACKER_ID/global', {
            // _trackerId: '179075-204259',
                scope: 'recommender-setup',
                ...payload.data,
            });
        },
        getRecommendersDefinitions() {
            return reccoAxiosInstance.get('/definitions/REPLACE_TRACKER_ID', {
                data: {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        getRecommendersTemplates() {
            return reccoAxiosInstance.get('/setup/REPLACE_TRACKER_ID/templates', {
                data: {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        getRecommenderSetup(payload = { type: undefined }) {
            const urlSuffix = payload.type ? `${payload.type}` : '';
            return reccoAxiosInstance.get(`/setup/REPLACE_TRACKER_ID/types/${urlSuffix}`, {
                data: {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        getAllRecommenderSetups() {
            return reccoAxiosInstance.get('/setup/REPLACE_TRACKER_ID/types', {
                data: {
                    // _trackerId: '179075-204259',
                    scope: 'recommender-setup',
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        saveRecommenderSetup(payload = { data: undefined }) {
            return reccoAxiosInstance.post('/setup/REPLACE_TRACKER_ID/types/', {
                // _trackerId: '179075-204259',
                scope: 'recommender-setup',
                ...payload.data,
            });
        },
        deactivateRecommenderSetup(payload = { type: undefined }) {
            return reccoAxiosInstance.post(`/setup/REPLACE_TRACKER_ID/types/${payload.type}/deactivate`, {
                scope: 'recommender-setup',
            });
        },
        activateRecommenderSetup(payload = { type: undefined }) {
            return reccoAxiosInstance.post(`/setup/REPLACE_TRACKER_ID/types/${payload.type}/activate`, {
                scope: 'recommender-setup',
            });
        },
        deleteRecommenderSetup(payload = { type: undefined }) {
            return reccoAxiosInstance.delete(`/setup/REPLACE_TRACKER_ID/types/${payload.type}`, {
                data: {
                    scope: 'recommender-setup',
                },
            });
        },
    },
    rewriteQuery: {
        loadData(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/rewrite_query`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        getCurrency(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/rewrite_query/currency`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        save(payload = { siteId: undefined, customization: undefined, id: undefined }) {
            if (payload.id) {
                return axiosInstance.put(`/sites/${payload.siteId}/rewrite_query`, {
                    query: payload.customization.query,
                    new_query: payload.customization.rewrittenQuery,
                    filters: payload.customization.filters,
                    id: payload.id,
                    admit_rewrite: payload.customization.admit_rewrite,
                    message: payload.customization.message,
                });
            }
            return axiosInstance.post(`/sites/${payload.siteId}/rewrite_query`, {
                query: payload.customization.query,
                new_query: payload.customization.rewrittenQuery,
                filters: payload.customization.filters,
                admit_rewrite: payload.customization.admit_rewrite,
                message: payload.customization.message,
            });
        },
        delete(payload = { siteId: undefined, id: undefined }) {
            return axiosInstance.delete(`/sites/${payload.siteId}/rewrite_query/${payload.id}`);
        },
        loadFilters(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/filters`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        loadDetail(payload = { siteId: undefined, id: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/rewrite_query/${payload.id}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        loadTypes(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/types`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
    },
    catalog: {
        getCatalog: ({ trackerId }) => axiosInstance.get(`/sites/${trackerId}/catalog_management/catalog_browser`, {
            data: {},
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        findByIds: ({ trackerId, ids }) => axiosInstance.get(`/sites/${trackerId}/catalog_search/find_by_ids`, {
            data: {},
            params: {
                ids,
            },
            headers: {
                accept: 'application/json',
            },
        }),
    },
    search: {
        getConfig: ({ trackerId }) => axiosInstance.get(`/sites/${trackerId}/setup/search`, {
            data: {},
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        getProductionConfig: ({ trackerId }) => axiosInstance.get(`/sites/${trackerId}/setup/search/production_configuration`, {
            data: {},
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        saveConfig: ({
            trackerId, config, version, publish, preview, title, message,
        }) => axiosInstance.put(`/sites/${trackerId}/setup/search`, {
            config: JSON.stringify(config),
            version: JSON.stringify(version),
            publish: JSON.stringify(publish),
            create_preview_url: JSON.stringify(false),
            create_edge_url: JSON.stringify(!!preview),
            title,
            message,
            modified: title ? 'true' : undefined, // i dont even...
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        activate: ({ trackerId }) => axiosInstance.patch(`/sites/${trackerId}/setup/search/enable`, {
            has_managed_search: JSON.stringify(true),
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        disable: ({ trackerId }) => axiosInstance.patch(`/sites/${trackerId}/setup/search/disable`, {
            has_managed_search: JSON.stringify(false),
        }, {
            params: {},
            headers: {
                accept: 'application/json',
            },
        }),
        getColorScheme({ trackerId, color }) {
            return axiosInstance.get(`/sites/${trackerId}/setup/search/color_scheme`, {
                params: {
                    primary_color: color,
                },
                headers: {
                    Accept: 'application/json',
                },
            });
        },
    },
    shopware: {
        saveSetup(payload = { params: undefined }) {
            return axiosInstance.post('/shopware/setup', {
                params: payload.params,
            });
        },
    },
    magento: {
        saveSetup(payload = { params: undefined }) {
            return axiosInstance.post('/magento/setup', {
                params: payload.params,
            });
        },
    },
    idosell: {
        saveSetup(payload = { params: undefined }) {
            return axiosInstance.post('/idosell/webhooks/setup', {
                ...payload.params
            });
        },
    },
    settings: {
        loadSites(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/settings/integration_settings`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        deleteFeed(payload = { siteId: undefined, id: undefined }) {
            return axiosInstance.delete(`/sites/${payload.siteId}/feeds/${payload.id}`);
        },
        resetCatalog(payload = { site: undefined }) {
            return axiosInstance.delete(`/sites/${payload.site.tracking_code}/settings/catalogs`, {
                data: {
                    site_id: payload.site.tracking_code,
                    confirm_name: payload.site.name,
                },
            });
        },
        saveShoptet(payload = { siteId: undefined, settings: undefined }) {
            return axiosInstance.put(`/sites/${payload.siteId}/settings/setup_shoptet_integration`, {
                feature_custom_price_list: payload.settings.feature_custom_price_list,
                feature_quantity_discounts: payload.settings.feature_quantity_discounts,
                feature_foreign_languages: payload.settings.feature_foreign_languages,
            });
        },
    },
    auditLog: {
        load({
            siteId, page, size, filters, query, dates, sort,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.get(`/sites/${tc}/audit_log/events`, {
                headers: {
                    Accept: 'application/json',
                },
                params: {
                    page,
                    size,
                    filters,
                    dates,
                    query,
                    sort,
                },
            });
        },
    },
    synonyms: {
        load({
            siteId, page, perPage, query, type,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.get(`/sites/${tc}/catalog_management/synonyms/page_json`, {
                headers: {
                    Accept: 'application/json',
                },
                params: {
                    page: page || 1,
                    per_page: perPage || 10,
                    q: query || undefined,
                    type,
                },
            });
        },
        preview({
            siteId, synonyms, rootWord, operation, id,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.post(`/sites/${tc}/catalog_management/synonyms/preview_json`, {
                synonyms: synonyms ? synonyms.join(',') : undefined,
                root_word: rootWord,
                operation,
                synonym_id: id,
            }, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        create({
            siteId, synonyms, rootWord, type,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.post(`/sites/${tc}/catalog_management/synonyms/create_json`, {
                synonyms: synonyms ? synonyms.join(',') : undefined,
                root_word: rootWord,
                type,
            }, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        update({
            siteId, synonyms, id, type, rootWord,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.post(`/sites/${tc}/catalog_management/synonyms/update_json`, {
                synonyms: synonyms ? synonyms.join(',') : undefined,
                id,
                type,
                root_word: rootWord,
            }, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        delete({
            siteId, id,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.post(`/sites/${tc}/catalog_management/synonyms/destroy_json`, {
                id,
            }, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        loadRecommended({
            siteId, page, perPage, type,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.get(`/sites/${tc}/catalog_management/synonym_recommendations/page_json`, {
                headers: {
                    Accept: 'application/json',
                },
                params: {
                    page: page || 1,
                    per_page: perPage || 10,
                    type,
                },
            });
        },
        previewRecommended({
            siteId, id, rootWord, synonyms,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.post(`/sites/${tc}/catalog_management/synonym_recommendations/preview_json`, {
                synonym_recommendation_id: id,
                root_word: rootWord,
                synonyms: synonyms ? synonyms.join(',') : undefined,
            }, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        updateRecommended({
            siteId,
            id,
            synonyms,
            rootWord,
            reject,
            type,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.post(`/sites/${tc}/catalog_management/synonym_recommendations/update_json`, {
                id,
                synonyms: synonyms ? synonyms.join(',') : undefined,
                root_word: rootWord,
                accept: !reject,
                reject: !!reject,
                type,
            }, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        import({
            siteId, items, type, meaningful,
        }) {
            const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
            return axiosInstance.post(`/sites/${tc}/catalog_management/synonyms/bulk_upload`, {
                data: {
                    items,
                    type,
                    meaningful,
                },
            });
        },
        async export({ siteId, filename } = {}) {
            try {
                const tc = siteId || window.LBXFrontendData.siteData.trackingCode;
                const response = await axiosInstance.get(`/sites/${tc}/catalog_management/synonyms/bulk_download`, {
                    headers: {
                        accept: 'text/csv',
                    },
                });
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename || 'exported-synonyms.csv';
                link.click();
            } catch (error) {
                $handleError(error);
            }
        },
    },
    ranking: {
        load({ siteId = window.LBXFrontendData.siteData.trackingCode } = {}) {
            return axiosInstance.get(`/sites/${siteId}/search_ranking/data`);
        },
        save({ signals, siteId = window.LBXFrontendData.siteData.trackingCode } = {}) {
            return axiosInstance.post(`/sites/${siteId}/search_ranking/`, {
                signals,
            });
        },
    },
    configurations: {
        create({ siteId = window.LBXFrontendData.siteData.trackingCode, data } = {}) {
            return axiosInstance.post(`/sites/${siteId}/configurations`, data);
        },
        checkTitle({ siteId = window.LBXFrontendData.siteData.trackingCode, title } = {}) {
            return axiosInstance.get(`/sites/${siteId}/configurations/is_title_unique`, {
                params: {
                    title,
                },
            });
        },
        save({ siteId = window.LBXFrontendData.siteData.trackingCode, data } = {}) {
            return axiosInstance.put(`/sites/${siteId}/configurations/${data.id}`, data);
        },
        saveNote({ siteId = window.LBXFrontendData.siteData.trackingCode, data } = {}) {
            return axiosInstance.post(`/sites/${siteId}/configurations/${data.id}/note`, data);
        },
        loadDetail({ siteId = window.LBXFrontendData.siteData.trackingCode, id } = {}) {
            return axiosInstance.get(`/sites/${siteId}/configurations/${id}`);
        },
        disable({ siteId = window.LBXFrontendData.siteData.trackingCode, id } = {}) {
            return axiosInstance.post(`/sites/${siteId}/configurations/${id}/deactivate`);
        },
        loadSiteConfiguration({ siteId = window.LBXFrontendData.siteData.trackingCode } = {}) {
            return axiosInstance.get(`/sites/${siteId}/configurations/active_configuration`);
        },
        loadNewDetail({ siteId = window.LBXFrontendData.siteData.trackingCode } = {}) {
            return axiosInstance.get(`/sites/${siteId}/configurations/new`);
        },
        loadList({
            siteId = window.LBXFrontendData.siteData.trackingCode,
            inactive = false,
            page = 1,
            pageSize = 10,
            query = undefined,
        } = {}) {
            return axiosInstance.get(`/sites/${siteId}/configurations`, {
                params: {
                    status: inactive ? 'inactive' : 'active',
                    page,
                    size: pageSize,
                    query,
                },
            });
        },
    },
    abTests: {
        loadList({
            siteId = window.LBXFrontendData.siteData.trackingCode,
            query = undefined,
        } = {}) {
            return axiosInstance.get(`/sites/${siteId}/ab_tests`, {
                params: {
                    query,
                },
            });
        },
        loadConfigurations({ siteId = window.LBXFrontendData.siteData.trackingCode } = {}) {
            return axiosInstance.get(`/sites/${siteId}/configurations/all`);
        },
        loadDetail({ siteId = window.LBXFrontendData.siteData.trackingCode, id } = {}) {
            return axiosInstance.get(`/sites/${siteId}/ab_tests/${id}`);
        },
        loadAnalytics({ siteId = window.LBXFrontendData.siteData.trackingCode, id } = {}) {
            return axiosInstance.get(`/sites/${siteId}/ab_tests/${id}/analytics`);
        },
        create({ siteId = window.LBXFrontendData.siteData.trackingCode, data } = {}) {
            return axiosInstance.post(`/sites/${siteId}/ab_tests`, data);
        },
        save({ siteId = window.LBXFrontendData.siteData.trackingCode, data } = {}) {
            return axiosInstance.put(`/sites/${siteId}/ab_tests/${data.id}`, data);
        },
        delete({ siteId = window.LBXFrontendData.siteData.trackingCode, id } = {}) {
            return axiosInstance.delete(`/sites/${siteId}/ab_tests/${id}`);
        },
        stop({ siteId = window.LBXFrontendData.siteData.trackingCode, id } = {}) {
            return axiosInstance.post(`/sites/${siteId}/ab_tests/${id}/stop`);
        },
        saveInternalNote({ siteId = window.LBXFrontendData.siteData.trackingCode, id, internal_note } = {}) {
            return axiosInstance.put(`/sites/${siteId}/ab_tests/${id}/internal_note`, { internal_note });
        },
    },
};

Vue.prototype.$http = axiosInstance;
