---
en:
  app:
    emailExample: email@address.com
    loginPassword: Login password
    password: Password
    passwordAgainError: Passwords do not match
    passwordValidation:
      maxChars: max. {x} characters
      minChars: min. {x} characters
      notContainsDigit: a number
      notContainsUpperCase: a capital letter
      passwordNeeds: Your password needs
  createProject:
    addCodeToSite: "Adding code to sites 👷"
    addGroupName: Enter group name
    addNewToExisting: Add a new site to an existing project
    addProject: Add project
    addProjectSite: Add project/site
    allAccessInfo: We recommend if you want to add a related page to the existing ones (eg a domain with another language). All users within the project will have access to the site.
    assignToProject: Assign to project group
    chooseLanguage: Choose a language
    choosePlatform: Choose the website platform
    chooseProject: Choose project
    chooseSegment: Choose industry segment
    createNew: Create a new project and site
    createSite: "Create a new site/project 📝"
    decided: 'You have decided:'
    existingGroups: Existing groups in the project
    goBack: Go back
    needsHelp: If you need help or your solution is not built on this platform, <a href="https://www.luigisbox.com/contact/" target="_blank">contact our support</a>.
    notifications:
      ProjectCreationFailed: Project creation has failed.
      ProjectLimitReached: Project count limit reached.
      ProjectNotFound: Selected project was not found.
      undefined: Something went wrong.
    pageLanguage: Website language
    platform:
      Bigcommerce: BigCommerce
      Bsshop: BSSHOP
      Commercetools: CommerceTools
      Contentspeed: ContentSpeed
      Cscart: CS-Cart
      Fastcentrik: FastCentrik (NetDirect)
      Goshop: GoShop
      Iai: IAI
      Kenticocms: Kentico CMS
      Magento2: Magento 2
      Marco: Marco (Cognito)
      Nopcommerce: nopCommerce
      Opencart: OpenCart
      Oscommerce: osCommerce
      Oxyshop: oXyShop
      Peckadesign: PeckaDesign
      Prestashop: PrestaShop
      Redcart: RedCart
      Rshop: RSHOP
      Shopcentrik: ShopCentrik (NetDirect)
      Shopware5: Shopware 5
      Shopware6: Shopware 6
      Shopware6cloud: Shopware 6 Cloud
      Smartbase: SmartBase
      Upgates: UPgates
      Woocommerce: WooCommerce
      Wordpress: WordPress
      Wpj: wpj
      Xcart: X-Cart
      addName: Add the platform name
      another: Another platform
      custom: Custom
      other: Other
      title: Platform
    projectName: Project name
    projectNameExample: For example, the company name
    search: Browse
    seemsExists: It looks like the project with the address <b>{websiteAddress}</b> already exists in Luigi's Box. Try to contact the owner or ask your team to add you.
    segment:
      title: Segment
    shopify:
      subTitle: "<b>Login to</b> your Shopify e-shop <b>and manage Luigi's Box with your plugin.</b> If you don't already own the Luigi's Box add-on, simply add it via the add-ons manager."
      title: "<b>{websiteAddress}</b> appears to use<br> the {shopifyIcon} Shopify platform"
    shoptet:
      subTitle: "<b>Login to</b> your Shoptet e-shop <b>and manage Luigi's Box with your plugin.</b> If you don't already own the Luigi's Box add-on, simply add it via the add-ons manager."
      title: "<b>{websiteAddress}</b> appears to use<br> the {shoptetIcon} Shoptet platform"
    shopware:
      subTitle: "<b>Login to</b> your Shopware e-shop <b>and manage Luigi's Box with your plugin.</b> If you don't already own the Luigi's Box add-on, simply add it via the add-ons manager."
      title: "<b>{websiteAddress}</b> appears to use<br> the {shopwareIcon} Shopware platform"
    subTitle: A project is a common place for all websites, users, and payments that belong to it.
    title: Create your first project
    urlAddress: The URL of the website
    urlAddressExample: domainname.com
  emailVerification:
    checkSpamText: If you do not receive the e-mail immediately, check your spam folder.
    checkYourEmail: "📩 Check your e-mail"
    emailConfirmText: To start using Luigi's Box, please <b>confirm your e-mail address.</b>
    emailNotReceived: You still haven't received the e-mail? Try
    emailNotReceivedPart2: sending it again.
    emailSent: We have sent a verification e-mail to your e-mail address.
    expiredLink: "⚠️ Invalid link"
    inProgress: Verification in progress
    invalidLinkText: Your link seems to be invalid, if you want to complete your registration, you need to
    invalidLinkText2: resend your verification email.
    newEmailSent: We have sent another verification e-mail to your e-mail address.
  newPassword:
    confirmPassword: Confirm new password
    passwordAgain: Repeat the new password
    setPassword: Enter the new password
    subTitle: Enter a new password to log into your Luigi's Box account.
    title: " 🔐 Choose a new password!"
  projectLastStep:
    checkIntegration: Verify the integration
    codeFound: Code has been successfully added to your website. Go to the project now.
    copied: Copied!
    developerAddress: developers@address.com
    emailNotSent: Instructions have not been sent to <b>{emailAddress}</b>. Try again later.
    emailSent: Instructions have been successfully sent to <b>{emailAddress}</b>
    finishIntegrationText: For us to start Luigi's Box services and complete all the setup, put the code below in the <head></head> section of all your pages.
    forwardInstructions: Forward the instructions for code insertion to your agency or developer.
    goToProject: Go to project
    gtmInsert: If you don't have the option to insert the code into the pages directly, you can also easily insert it using GTM and <a href="https://youtu.be/xgeid9Eks1w">this tutorial.</a>
    info: We need to <b>add a piece of our code to your site</b> to get started. Don't have time right now? You can go to the project and complete the integration later.
    insertCode: Inserting the code into pages
    moreInfo: You can find detailed information about the integration on our <a href="https://www.luigisbox.com/contact/" target="_blank">technical support page</a>.
    noCodeFound: We're sorry, but we haven't found the code on the site yet.
    sendInstructions: Send instructions
    shareInstructionsTitle: Share instructions
    subTitle: You have successfully added project <b>{projectName}</b> and site <b>{siteName}</b> to Luigi's Box!
    title: " 🚀 The last step"
  resetPassword:
    accountEmail: Your account e-mail address
    backToSignin: Back to Log in
    emailSentSuccessfully: Thank you! An email to verify account ownership has been sent. If you have not received an email, <a href="https://www.luigisbox.com/contact/" target="_blank">please contact our support.</a>
    sendEmail: Send e-mail
    subTitle: Don't worry! Enter your e-mail address and we'll send you a link to set up a new password.
    title: "Forgotten 🔐 password?"
  signup:
    acknowledge: I agree with <a href="https://www.luigisbox.com/terms-of-service/" target="_blank">the terms and conditions</a> and <a href="https://www.luigisbox.com/privacy/" target="_blank">the processing of personal data.</a>
    allRequired: "* All fields are required"
    chooseOption: Choose an option...
    createAccount: Confirm and submit
    createAccountForFree: Create a <b>free account</b> and get Luigi's Box <b>services free for 30 days!</b>
    email: Work e-mail address
    exampleEmail: work@address.com
    fastTime: Takes no more than 2 minutes
    freeAccount: Sign Up
    inviteInfo: Fill in the missing information.
    inviteTitle: Information completion
    name: Full name
    nameAndSurname: Name and surname
    noCode: No programming skills required
    noCreditCard: No credit card required
    notifications:
      PasswordsDoNotMatch: Passwords do not match. Please, try again.
      UserNotConfirmed: You are not confirmed. Please, confirm your e-mail address.
      emailAlreadyInUse: E-mail address is already in use. Please try to <a href="sign_in">log in</a>.
      invalidTokenPassword: The provided token is not valid. Please, <a href="/users/password/new">reset your password again</a>.
      missingRequiredParameters: Some of the required parameters are missing.
      passwordNotChanged: Password has not been changed, try again later.
      tryAgainLater: Too many requests, try again in a minute.
      userCreationFailed: User creation failed.
    pageCreation: Create new account
    phoneNumber: Phone number
    signin: Already a Luigi's Box user? <a href="sign_in">Log in.</a>
    signup: Sign Up
    web: Website URL address
    webUrlExample: https://www.website.com/
    yourRole: Your role in the organization
