<template>
  <b-input-group class="input-group-merge lbx-number-input">
    <b-input-group-prepend
      is-text
      @click="add(-1)"
    >
      <span>−</span>
    </b-input-group-prepend>
    <b-form-input
      ref="inputRef"
      v-bind="[$attrs]"
      v-on="$listeners"
    />
    <b-input-group-append
      is-text
      @click="add(1)"
    >
      <span>+</span>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { ref } from 'vue';

export default {
    props: {
        min: {
            type: [Number, String],
            default: null,
        },
        max: {
            type: [Number, String],
            default: null,
        },
    },
    setup(props) {
        const inputRef = ref(null);

        const add = n => {
            const input = inputRef.value?.$el; // raw input
            if (!input) {
                return;
            }
            let v = Number(input.value);
            if (Number.isNaN(v)) {
                return;
            }

            if (input.disabled) {
                return;
            }

            v += n;

            if (props.min !== null && v < Number(props.min)) {
                return;
            }
            if (props.max !== null && v > Number(props.max)) {
                return;
            }

            input.value = v;

            input.dispatchEvent(
                new Event('input', {
                    bubbles: true,
                    cancelable: true,
                }),
            );
        };

        return {
            add,
            inputRef,
        };
    },
};
</script>

<style lang="scss" scoped>

  .lbx-number-input
  {
    width: 120px;
  }

  .input-group-prepend,
  .input-group-append
  {
    cursor: pointer !important;
  }

  .form-control
  {
    text-align: center;
  }

    .input-group-prepend ::v-deep .input-group-text,
    .input-group-append ::v-deep .input-group-text
    {
      padding-left: 0;
      padding-right: 0;
      line-height: 1.45rem;

      span
      {
        padding-left: 1rem;
        padding-right: 1rem;
        color: var(--lbxapp-secondary);
        font-size: 1.25rem;
      }
    }

    .input-group-prepend ::v-deep .input-group-text span
    {
      border-right: 1px solid var(--lbxapp-border) !important;
      margin-right: 1px;
    }
    .input-group-append ::v-deep .input-group-text span
    {
      border-left: 1px solid var(--lbxapp-border) !important;
      margin-left: 1px;
    }

</style>
