import Vue, { computed } from 'vue';
import { debounce, cloneDeep } from 'lodash-es';
import { $t } from '@/libs/i18n';
import notification from '@/views/components/notification';

import api from '@/libs/api';

const REVERT_TO_PRODUCTION_CONFIG = 'REVERT_TO_PRODUCTION_CONFIG';

export const transformAutocompleteConfig = cfg => {
    const configToSave = cloneDeep(cfg);
    configToSave.autocomplete.types = configToSave.autocomplete.types
        // .filter(t => !t.disabled)
        .map((t, tind) => {
            t.position = tind + 1;
            return t;
        });
    if (configToSave.wait_for_selectors) {
        // remove empty strings
        configToSave.wait_for_selectors = configToSave.wait_for_selectors
            .filter(selector => selector);
    }

    try {
        // remove empty translations
        const { locale } = configToSave.autocomplete;

        Object.keys(configToSave.autocomplete.translations[locale].types).forEach(type => {
            Object.keys(configToSave.autocomplete.translations[locale].types[type]).forEach(key => {
                configToSave.autocomplete.translations[locale]
                    .types[type][key] = configToSave.autocomplete.translations[locale]
                        .types[type][key] || undefined;
            });
        });
    } catch (error) {
        // silent fail
    }

    return configToSave;
};

const getDefaultState = () => ({
    globalSettings: null,
    autocomplete: null, // config
    autocompleteFull: null, // complete response
    autocompleteProduction: null, // production response
    search: null, // config
    searchAttributes: [],
    searchTemplates: {}, // default templates, populated from CDN
    catalog: {},
    searchFull: null, // complete response
    searchProduction: null, // production response
    feeds: [], // response for get feeds
    feedsProcessDone: false, // processing of all feeds
    integrationInfo: null, // info about current integration
    recommenderConfigurations: [],
    recommender: {}, // config
    currentRecommenderId: '',
    recommenderFull: null, // complete response
    recommenderProduction: null, // production response
    recommenderSettingSetups: [],
    notAuthorized: false,
    notAuthMessage: null,
    allRecommenderDefinitions: [],
    recommenderSetting: null,
    recommenderSettingInitial: null, // this is first setting (from template or first save)
    isRecommenderGlobalPresent: false,
    recommenderStructure: {
        recommendersTemplates: [],
        attributes: {
            availability_rank_field: {
                value_type: 'default',
                value: null,
            },
            availability_field: {
                value_type: 'default',
                value: null,
            },
            boost_field: {
                value_type: 'default',
                value: null,
            },
            discount_field: {
                value_type: 'default',
                value: null,
            },
            introduced_at_field: {
                value_type: 'default',
                value: null,
            },
            popularity_field: {
                value_type: 'default',
                value: null,
            },
            price_field: {
                value_type: 'default',
                value: null,
            },
            margin_field: {
                value_type: 'default',
                value: null,
            },
            absolute_margin_field: {
                value_type: 'default',
                value: null,
            },
        },
        categoryBlacklist: [],
        crossSellPairs: [],
        preferredMainCategory: [],
    },
    catalogTypes: [],
});

const namespace = 'setup'; // same key as registered in @/store/index.js

export const commaListToArray = str => {
    if (!str) {
        return [];
    }
    if (typeof str !== 'string') {
        return str;
    }
    return str
        .split(',')
        .map(s => s.trim())
        .filter(Boolean);
};

const setup = {
    namespaced: true,
    state: getDefaultState(),
    getters: {

    },
    mutations: {
        SET_SEARCH_TEMPLATE(state, payload) {
            state.searchTemplates[payload.id] = payload.value;
        },
        SET_CONFIG(state, payload) {
            const { type, other } = payload;
            const config = cloneDeep(payload.config);
            if (type === 'search') {
                if (config.search.eval_facets) {
                    config.search.eval_facets = false;
                    config.search.facets = [];
                    // eslint-disable-next-line no-alert
                    alert('eval_facets is deprecated, please move this logic into initialize before saving configuration. Use old setup screen.');
                }

                if (config.search.eval_sorts) {
                    config.search.eval_sorts = false;
                    config.search.sorts = [];
                    // eslint-disable-next-line no-alert
                    alert('eval_sorts is deprecated, please move this logic into initialize before saving configuration. Use old setup screen.');
                }

                if (config.search.eval_locale) {
                    config.search.eval_locale = false;
                    config.search.sorts = 'en';
                    // eslint-disable-next-line no-alert
                    alert('eval_locale is deprecated, please move this logic into initialize before saving configuration. Use old setup screen.');
                }

                [
                    'remove_fields',
                    'facets',
                    'sorts',
                    'quicksearch_types',
                    'top_items',
                ].forEach(key => {
                    config.search[key] = commaListToArray(config.search[key]);
                });
            }
            if (type === 'autocomplete') {
                let types = (config.autocomplete.types || []);

                // add all available types if not present
                (
                    other?.available_types
                     || state.autocompleteFull?.available_types
                     || [])
                    .filter(at => at !== 'variant')
                    .forEach(at => {
                        const present = types.find(t => t.type === at);
                        if (!present) {
                            types.push({
                                type: at,
                                context: '',
                                placement: '',
                                size: 7,
                                name: '',
                                attributes: [],
                                recommend: {
                                    size: 7,
                                },
                                defaultFilters: null,
                                disabled: false,
                            });
                        }
                    });

                // recompute positions and set defaults
                types = types.map((type, ind) => {
                    type.position = ind + 1;
                    type.disabled ||= false;
                    type.phrases ||= false;
                    return type;
                });

                types.sort(a => {
                    if (a.type === 'product') {
                        return -1;
                    }
                    if (a.type === 'item') {
                        return -1;
                    }
                    return 1;
                });

                config.autocomplete.types = types;
                config.autocomplete.auto_reposition ||= true;

                if (!config.autocomplete.locale) {
                    config.autocomplete.locale = 'en';
                }

                config.autocomplete.translations ||= {};
                config.autocomplete.translations[config.autocomplete.locale] ||= {};

                // TODO create translation object from deprecated options
                // hint, placeholderText, showAllTitle, typesStuff

                delete config.autocomplete.no_results_message;
            }
            state[type] = config;
        },
        DEEP_SET(state, data) {
            const { type, path, payload } = data;
            const typeCconfig = cloneDeep(state[type]);
            let config = typeCconfig;
            for (let i = 0; i < path.length; i += 1) {
                const part = path[i];
                // not the last one
                if (i < path.length - 1) {
                    if (config[part] === undefined) {
                        config[part] = {};
                        Vue.set(config, part, {});
                    }
                    config = config[part];
                } else {
                    config[part] = payload;
                }
            }
            Vue.set(state, type, typeCconfig);
        },
    },
    actions: {
        // autocomplete
        async useAutocompleteProductionConfig({
            dispatch, state, commit,
        }) {
            commit('SET_CONFIG', { type: 'autocomplete', config: state.autocompleteProduction.configuration_json });
            await dispatch('saveAutocompleteConfig', { publish: true, preview: false, title: REVERT_TO_PRODUCTION_CONFIG });
        },
        async saveAutocompleteConfig({
            rootState, dispatch, state, commit,
        },
        {
            publish = false, preview = false, title, message,
        }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                if (state.autocompleteFull.has_managed_autocomplete === false) {
                    await dispatch('activateAutocomplete', { silent: true });
                }
                const configToSave = transformAutocompleteConfig(state.autocomplete);
                const { data } = await api.autocomplete.saveConfig({
                    trackerId: rootState.app.siteId,
                    config: configToSave,
                    version: state.autocompleteFull?.latest_configuration?.id,
                    publish,
                    preview,
                    title,
                    message,
                });
                if (publish) {
                    notification(null, $t('acSetup.toasts.savedAndPublished'));
                } else {
                    notification(null, $t('acSetup.toasts.saved'));
                }
                if (data.preview_edge_url) {
                    const win = window.open(data.preview_edge_url, '_blank');

                    if (!win || typeof win === 'undefined') {
                        notification(null, $t('acSetup.toasts.popupBlocked'), 'warning');
                    } else {
                        win.focus();
                    }
                }
                commit('app/UNBLOCK_LEAVE', null, { root: true });
            } catch (error) {
                if (error.response?.status === 409) {
                    api.$handleError(null, { fallbackMessage: 'config_conflict' });
                } else if (error.response?.data?.error_message) {
                    // eslint-disable-next-line no-control-regex
                    console.error(error.response?.data?.error_message.replace(/[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g, ''));
                    api.$handleError(null);
                } else {
                    api.$handleError(error);
                }
            }

            await dispatch('loadAutocompleteSetupData');
            commit('app/STOP_LOADING', null, { root: true });
        },
        async activateAutocomplete({
            rootState, dispatch, commit, state,
        }, options = {}) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.autocomplete.activate({ trackerId: rootState.app.siteId });
                if (!state.autocomplete) {
                    await dispatch('loadAutocompleteSetupData');
                }
                if (!options.silent) {
                    notification(null, $t('acSetup.toasts.activated'));
                }
            } catch (error) {
                api.$handleError(error);
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async disableAutocomplete({ rootState, dispatch, commit }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.autocomplete.disable({ trackerId: rootState.app.siteId });
                await dispatch('loadAutocompleteSetupData');
                notification(null, $t('acSetup.toasts.disabled'));
            } catch (error) {
                api.$handleError(error);
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async getAutocompleteColorScheme({ rootState, commit }, color) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.autocomplete
                    .getColorScheme({ trackerId: rootState.app.siteId, color });
                commit('app/STOP_LOADING', null, { root: true });
                return data;
            } catch (error) {
                api.$handleError(error);
                commit('app/STOP_LOADING', null, { root: true });
                // pass error
                throw error;
            }
        },
        async loadAutocompleteSetupData({ commit, rootState }, options = { throwError: false }) {
            commit('app/START_LOADING', null, { root: true });
            let data;
            try {
                data = (await api.autocomplete.getConfig({ trackerId: rootState.app.siteId })).data;
                commit('SET_CONFIG', { type: 'autocompleteFull', config: data });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                if (options.throwError) {
                    throw error;
                }
                api.$handleError(error);
                return;
            }
            const config = data.autocomplete_config;
            commit('SET_CONFIG', { type: 'autocomplete', config, other: { available_types: data.available_types } });

            try {
                let productionData = (await api.autocomplete
                    .getProductionConfig({ trackerId: rootState.app.siteId })).data;
                if (JSON.stringify(productionData) === '{}') {
                    productionData = null;
                }
                commit('SET_CONFIG', { type: 'autocompleteProduction', config: productionData });
            } catch (error) {
                api.$handleError(error);
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async loadFeeds({ commit, rootState }, options = { isLoading: true, hitFields: '' }) {
            let feedsProcessDone = false;
            let feedsQualityCheck = false;
            const finalHitFields = options.hitFields ? `${options.hitFields},run_status,run_error,quality_check_status,quality_check_error,checks,feed_attributes,mapping,variants` : '';
            commit('SET_CONFIG', { type: 'feedsProcessDone', config: feedsProcessDone });
            commit('SET_CONFIG', { type: 'feedsQualityCheck', config: feedsQualityCheck });
            if (options.isLoading) {
                commit('app/START_LOADING', null, { root: true });
            }
            let data;
            try {
                data = (await api.onboarding.feedsIntegration.loadFeeds({ siteId: rootState.app.siteId, hitFields: finalHitFields })).data;
                commit('SET_CONFIG', { type: 'feeds', config: data });
                if (data.find(fInfo => fInfo.run_status === 1 || fInfo.run_status === 2)) {
                    feedsProcessDone = false;
                } else {
                    feedsProcessDone = true;
                }

                if (data.find(fInfo => fInfo.quality_check_status === 1 || fInfo.quality_check_status === 2)) {
                    feedsQualityCheck = false;
                } else {
                    feedsQualityCheck = true;
                }
                commit('SET_CONFIG', { type: 'feedsQualityCheck', config: feedsQualityCheck });
                commit('SET_CONFIG', { type: 'feedsProcessDone', config: feedsProcessDone });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
            }
            if (options.isLoading) {
                commit('app/STOP_LOADING', null, { root: true });
            }
        },
        async validateFeeds({ commit, rootState }, isLoading = true) {
            if (isLoading) {
                commit('app/START_LOADING', null, { root: true });
            }
            try {
                await api.onboarding.feedsIntegration.validateFeeds({ siteId: rootState.app.siteId });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
            }
            if (isLoading) {
                commit('app/STOP_LOADING', null, { root: true });
            }
        },
        async saveMapping({ commit, rootState }, feed) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.onboarding.feedsIntegration.saveMapping({ siteId: rootState.app.siteId, feed });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
            }
            commit('app/UNBLOCK_LEAVE', null, { root: true });
            commit('app/STOP_LOADING', null, { root: true });
        },
        async refreshAttributes({ commit, rootState }, feedId) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.onboarding.feedsIntegration.refreshAttributes({ siteId: rootState.app.siteId, feedId });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
            }
        },
        async loadIntegrationInfo({ commit }) {
            const { data } = await api.integrationInfo();
            commit('SET_CONFIG', { config: data, type: 'integrationInfo' });
        },
        // global
        async fillSelectorsFromExistingConfigs({ commit, dispatch, state },
            options = { saveSettings: false }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await dispatch('loadGlobalSettings');

                let {
                    inputSelector,
                    wrapperSelector,
                } = state.globalSettings.latest_configuration.settings;

                if (!inputSelector || !wrapperSelector) {
                    try {
                        await dispatch('loadAutocompleteSetupData', { throwError: true });
                        await dispatch('loadSearchSetupData', { throwError: true });
                    } catch (error) {
                        // we dont have priviliges
                        // this can happen in integration settings page
                        commit('app/STOP_LOADING', null, { root: true });
                        return;
                    }

                    let oldInputSelector = state.search.input_selector
                    || state.autocomplete.selector;
                    let oldWrapperSelector = state.search.wrapper_element_selector;

                    const useGS = 'USE_GLOBAL_SETTINGS';
                    if (oldInputSelector === useGS) {
                        oldInputSelector = '';
                    }
                    if (oldWrapperSelector === useGS) {
                        oldWrapperSelector = '';
                    }

                    let updated = false;

                    // these are taken from
                    // luigis/app/lib/tag_manager/autocomplete_default_settings.rb
                    // luigis/app/lib/tag_manager/search_settings.rb

                    const defaultInputSelectors = [
                        '#luigi-search-input',
                        '#luigi-ac-input',
                        '[itemprop="query-input"]',
                        'form[action="/search"] input[type="text"], form[action="/search"] input[type="search"]',
                    ];
                    const defaultWrapperSelectors = [
                        '#content-wrapper',
                        '#MainContent',
                    ];

                    if (!inputSelector && oldInputSelector) {
                        updated = true;
                        state.globalSettings.latest_configuration.settings
                            .inputSelector = oldInputSelector;
                    }
                    if (!wrapperSelector && oldWrapperSelector) {
                        updated = true;
                        state.globalSettings.latest_configuration.settings
                            .wrapperSelector = oldWrapperSelector;
                    }

                    inputSelector = state.globalSettings.latest_configuration.settings
                        .inputSelector;
                    wrapperSelector = state.globalSettings.latest_configuration.settings
                        .wrapperSelector;

                    if (
                        !defaultInputSelectors.includes(inputSelector)
                        && !defaultWrapperSelectors.includes(wrapperSelector)
                    ) {
                        if (updated) {
                            if (options.saveSettings) {
                                await dispatch('saveGlobalSelectors');
                            }

                            // alert('Global selectors were updated from old configs, please review it.');
                        }
                    }
                }
            } catch (error) {
                notification(null, 'Something went wrong when updating global selectors from old configs', 'danger');
                console.error(error);
            }

            commit('app/STOP_LOADING', null, { root: true });
        },
        async saveGlobalSelectors({
            dispatch, commit, state, rootState,
        }) {
            let ok = true;
            commit('app/START_LOADING', null, { root: true });
            try {
                if (!state.globalSettings.has_managed_global_settings) {
                    await api.globalSettings.enable({
                        trackerId: rootState.app.siteId,
                    });
                }
                await api.globalSettings.saveConfig({
                    trackerId: rootState.app.siteId,
                    config: state.globalSettings.latest_configuration.settings,
                    version: state.globalSettings.latest_version,
                    preview: false,
                    publish: true,
                });
                await dispatch('loadGlobalSettings');
            } catch (error) {
                ok = falsa;
                if (error.response?.status === 409) {
                    api.$handleError(null, { fallbackMessage: 'config_conflict' });
                } else if (error.response?.data?.error_message) {
                    // eslint-disable-next-line no-control-regex
                    console.error(error.response?.data?.error_message.replace(/[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g, ''));
                    api.$handleError(null);
                } else {
                    api.$handleError(error);
                }
            }

            commit('app/STOP_LOADING', null, { root: true });
            return ok;
        },
        async loadGlobalSettings({ commit, rootState }) {
            commit('app/START_LOADING', null, { root: true });
            let data;
            try {
                data = (
                    await api.globalSettings.getConfig({ trackerId: rootState.app.siteId })
                ).data;

                if (!data.latest_configuration) {
                    data.latest_configuration = {};
                }
                if (!data.latest_configuration.settings) {
                    data.latest_configuration.settings = {};
                }

                try {
                    // eslint-disable-next-line no-eval
                    eval(`window.$EVAL_HELPER = ${data.latest_configuration.settings};`);
                    data.latest_configuration.settings = window.$EVAL_HELPER;
                } catch (error) {
                    //
                }

                commit('SET_CONFIG', { type: 'globalSettings', config: data });
            } catch (error) {
                console.error(error);
                api.$handleError(error);
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        // search
        async useSearchProductionConfig({
            dispatch, state, commit,
        }) {
            commit('SET_CONFIG', { type: 'search', config: state.searchProduction.configuration_json });
            await dispatch('saveSearchConfig', { publish: true, preview: false, title: REVERT_TO_PRODUCTION_CONFIG });
        },
        async saveSearchConfig({
            rootState, dispatch, state, commit,
        },
        {
            publish = false, preview = false, title, message,
        }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                if (state.searchFull.has_managed_search === false) {
                    await dispatch('activateSearch', { silent: true });
                }
                const configToSave = cloneDeep(state.search);
                /*
                Object.keys(configToSave).forEach(key => {
                    if (key.startsWith('templates[')) {
                        console.log('deeel', key);
                        delete configToSave[key];
                    }
                });
                */
                // go through all templates and remove "default" and empty ones

                if (configToSave.templates) {
                    Object.keys(state.searchTemplates).forEach(templateName => {
                        const toSave = (configToSave.templates[templateName] || '').trim();
                        const defaultOne = (state.searchTemplates[templateName] || '').trim();
                        if (
                            !toSave
                            // eslint-disable-next-line max-len
                            || toSave === defaultOne
                        ) {
                            delete configToSave.templates[templateName];
                        }
                    });
                }

                const { data } = await api.search.saveConfig({
                    trackerId: rootState.app.siteId,
                    config: configToSave,
                    version: state.searchFull?.latest_configuration?.id,
                    publish,
                    preview,
                    title,
                    message,
                });
                if (publish) {
                    notification(null, $t('searchSetup.toasts.savedAndPublished'));
                } else {
                    notification(null, $t('searchSetup.toasts.saved'));
                }
                if (data.preview_edge_url) {
                    const win = window.open(data.preview_edge_url, '_blank');

                    if (!win || typeof win === 'undefined') {
                        notification(null, $t('searchSetup.toasts.popupBlocked'), 'warning');
                    } else {
                        win.focus();
                    }
                }
                commit('app/UNBLOCK_LEAVE', null, { root: true });
            } catch (error) {
                if (error.response?.status === 409) {
                    api.$handleError(null, { fallbackMessage: 'config_conflict' });
                } else if (error.response?.data?.error_message) {
                    // eslint-disable-next-line no-control-regex
                    console.error(error.response?.data?.error_message.replace(/[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g, ''));
                    api.$handleError(null);
                } else {
                    api.$handleError(error);
                }
            }

            await dispatch('loadSearchSetupData');
            commit('app/STOP_LOADING', null, { root: true });
        },
        async activateSearch({
            rootState, dispatch, commit, state,
        }, options = {}) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.search.activate({ trackerId: rootState.app.siteId });
                if (!state.search) {
                    await dispatch('loadSearchSetupData');
                }
                if (!options.silent) {
                    notification(null, $t('searchSetup.toasts.activated'));
                }
            } catch (error) {
                api.$handleError(error);
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async disableSearch({ rootState, dispatch, commit }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.search.disable({ trackerId: rootState.app.siteId });
                await dispatch('loadSearchSetupData');
                notification(null, $t('searchSetup.toasts.disabled'));
            } catch (error) {
                api.$handleError(error);
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async getSearchColorScheme({ rootState, commit }, color) {
            // commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.search
                    .getColorScheme({ trackerId: rootState.app.siteId, color });
                // commit('app/STOP_LOADING', null, { root: true });
                return data;
            } catch (error) {
                api.$handleError(error);
                // commit('app/STOP_LOADING', null, { root: true });
                // pass error
                throw error;
            }
        },
        async loadSearchTemplates({ commit }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const templates = [
                    'active-filter',
                    'active-filters',
                    'additional-results',
                    'banner',
                    'checkbox-color',
                    'checkbox',
                    'error',
                    'facet-boolean',
                    'facet-date',
                    'facet-hierarchical',
                    'facet-multichoice',
                    'facet-numeric-range',
                    'facet-type',
                    'facet',
                    'facets',
                    'loading',
                    'no-results',
                    'pagination',
                    'quick-search-default',
                    'quick-searches',
                    'result-default',
                    'results',
                    'search',
                    'sort',
                    'top-items',
                    'voice-search-icon',
                    'voice-search-popup',
                ];
                const promises = [];
                templates.forEach(template => {
                    promises.push(api.$axios.get(`https://cdn.luigisbox.com/resources/search.js/templates/boo/${template}.html`));
                });
                const fulfilled = await Promise.all(promises);
                templates.forEach((template, ind) => {
                    commit('SET_SEARCH_TEMPLATE', { id: `template-${template}`, value: fulfilled[ind].data });
                });
            } catch (error) {
                api.$handleError(error);
            }

            commit('app/STOP_LOADING', null, { root: true });
        },
        async loadSearchSetupData({ commit, rootState }, options = { throwError: false }) {
            commit('app/START_LOADING', null, { root: true });
            let data;
            try {
                data = (await api.search.getConfig({ trackerId: rootState.app.siteId })).data;
                commit('SET_CONFIG', { type: 'searchFull', config: data });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                if (options.throwError) {
                    throw error;
                }
                api.$handleError(error);
                return;
            }
            const config = data.search_config;
            let catalog = {};

            try {
                catalog = (await api.catalog.getCatalog({ trackerId: rootState.app.siteId })).data;
            } catch (error) {
                //
            }

            commit('SET_CONFIG', { type: 'search', config });
            commit('SET_CONFIG', { type: 'catalog', config: catalog });

            try {
                let productionData = (await api.search
                    .getProductionConfig({ trackerId: rootState.app.siteId })).data;
                if (JSON.stringify(productionData) === '{}') {
                    productionData = null;
                }
                commit('SET_CONFIG', { type: 'searchProduction', config: productionData });
            } catch (error) {
                api.$handleError(error);
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async useRecommenderProductionConfig({
            dispatch, state, commit,
        }) {
            commit('SET_CONFIG', { type: 'recommender', config: state.recommenderProduction.recommender_config });
            await dispatch('saveRecommenderConfig', { publish: true, preview: false });
        },

        async loadRecommenderList({ commit, rootState }) {
            commit('app/START_LOADING', null, { root: true });
            let data;
            try {
                data = (await api.recommenders.getRecoList({ trackerId: rootState.app.siteId })).data;
                commit('SET_CONFIG', { type: 'recommenderConfigurations', config: data });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async loadRecommenderSetupData({ commit, rootState }, id) {
            commit('app/START_LOADING', null, { root: true });
            commit('SET_CONFIG', { type: 'currentRecommenderId', config: id });
            let data;
            try {
                data = (await api.recommenders
                    .getCurrentConfig({ trackerId: rootState.app.siteId, id })).data;
                commit('SET_CONFIG', { type: 'recommenderFull', config: data });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            const config = data.recommender_config;
            commit('SET_CONFIG', { type: 'recommender', config });
            try {
                let productionData = (await api.recommenders
                    .getProductionConfig({ trackerId: rootState.app.siteId })).data;
                if (JSON.stringify(productionData) === '{}') {
                    productionData = null;
                }
                commit('SET_CONFIG', { type: 'recommenderProduction', config: productionData });
            } catch (error) {
                api.$handleError(error);
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async saveRecommenderConfig({
            rootState, dispatch, state, commit,
        },
        {
            publish = false, preview = false, isDisabled = false, notificationEnabled = true,
        }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const configToSave = cloneDeep(state.recommender);
                if (isDisabled) {
                    state.recommender.is_disabled = isDisabled;
                } else if (state.recommender.is_disabled === true) {
                    delete state.recommender.is_disabled;
                }

                const { data } = await api.recommenders.saveConfig({
                    trackerId: rootState.app.siteId,
                    config: state.recommender,
                    publish,
                    preview,
                    id: state.currentRecommenderId,
                });
                // update id for render key
                try {
                    state.recommenderFull.latest_configuration.id = data.id;
                } catch (error) {
                    //
                }
                if (notificationEnabled) {
                    if (publish) {
                        notification(null, $t('recommenders.toasts.savedAndPublished'));
                    } else {
                        notification(null, $t('recommenders.toasts.saved'));
                    }
                }
                if (data.preview_edge_url) {
                    const win = window.open(data.preview_edge_url, '_blank');

                    if (!win || typeof win === 'undefined') {
                        notification(null, $t('recommenders.toasts.popupBlocked'), 'warning');
                    } else {
                        win.focus();
                    }
                }
                commit('app/UNBLOCK_LEAVE', null, { root: true });
            } catch (error) {
                api.$handleError(error);
            }

            // await dispatch('loadRecommenderSetupData', state.currentRecommenderId);
            commit('app/STOP_LOADING', null, { root: true });
        },
        async getRecommenderColorScheme({ rootState, commit }, color) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.recommenders
                    .getColorScheme({ trackerId: rootState.app.siteId, color });
                commit('app/STOP_LOADING', null, { root: true });
                return data;
            } catch (error) {
                api.$handleError(error);
                commit('app/STOP_LOADING', null, { root: true });
                // pass error
                throw error;
            }
        },
        async deleteRecommender({ commit, rootState }, recoConfig) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.recommenders.deleteRecommender({ trackerId: rootState.app.siteId, id: recoConfig.id, name: recoConfig.name });
                commit('app/STOP_LOADING', null, { root: true });
                notification(null, $t('recommenders.toasts.deleted'));
            } catch (error) {
                api.$handleError(error);
                commit('app/STOP_LOADING', null, { root: true });
                // pass error
                throw error;
            }
        },
        async getFacets({ rootState, commit }, { facets, load, query }) {
            if (load) {
                commit('app/START_LOADING', null, { root: true });
            }
            try {
                const { data } = await api.getAttributeValues({ siteId: rootState.app.siteId, facets, query });
                const returnedFacets = data.values?.length > 0 ? data : [];
                setTimeout(() => {
                    commit('app/STOP_LOADING', null, { root: true });
                }, 300);
                return returnedFacets;
            } catch (error) {
                api.$handleError(error);
                commit('app/STOP_LOADING', null, { root: true });
                throw error;
            }
        },
        async generateNewId({ rootState, commit }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.recommenders.generateNewId({ siteId: rootState.app.siteId });
                commit('SET_CONFIG', { type: 'currentRecommenderId', config: data });
                commit('app/STOP_LOADING', null, { root: true });
                return data;
            } catch (error) {
                api.$handleError(error);
                commit('app/STOP_LOADING', null, { root: true });
                throw error;
            }
        },
        async getFilters({ rootState, commit }, { forPins }) {
            commit('app/START_LOADING', null, { root: true });
            const paramsString = forPins ? '?for_pins=true' : '';
            try {
                const { data } = await api.recommenders.getFilters({ siteId: rootState.app.siteId, paramsString });
                commit('app/STOP_LOADING', null, { root: true });
                return data.filters;
            } catch (error) {
                api.$handleError(error);
                commit('app/STOP_LOADING', null, { root: true });
                throw error;
            }
        },
        async loadTypes({ commit, rootState }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.recommenders.loadTypes({ siteId: rootState.app.siteId });
                commit('SET_CONFIG', { type: 'catalogTypes', config: data.types });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async getRecommendersTemplates({ commit, rootState }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.recommenders.getRecommendersTemplates({ siteId: rootState.app.siteId });
                setup.state.recommenderStructure.recommendersTemplates = data;
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async getRecommenderSetup({ commit, rootState }, type) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.recommenders.getRecommenderSetup({ siteId: rootState.app.siteId, type });
                setup.state.recommenderSetting = data;
                setup.state.recommenderSettingInitial = cloneDeep(data);
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                console.log(error);
                // api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async getAllRecommenderSetups({ commit, rootState }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.recommenders.getAllRecommenderSetups({ siteId: rootState.app.siteId });
                setup.state.recommenderSettingSetups = data;
                setup.state.notAuthorized = false;
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                console.log(error);
                setup.state.notAuthorized = true;
                setup.state.notAuthMessage = error;
                // api.$handleError(error);
                // return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async getAllRecommenderDefinitions({ commit, rootState }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.recommenders.getRecommendersDefinitions();
                setup.state.allRecommenderDefinitions = data;
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                console.log(error);
                // api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async saveRecommenderSetup({ commit, rootState }, data) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.recommenders.saveRecommenderSetup({ data });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                const currentError = error?.response?.data?.reason || error?.response?.data?.error || error;
                const text = error?.response?.data?.exception_details;
                if (text && currentError) {
                    notification(currentError, JSON.stringify(text), 'danger');
                } else {
                    api.$handleError(error);
                }
                return error;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async saveGlobalRecoSettings({ rootState, commit }, data) {
            commit('app/START_LOADING', null, { root: true });
            const mappedData = {
                default_fields: data.attributes,
                // default_fields: {},
                blacklisted_categories: data.categoryBlacklist,
                cross_sell_pairs: data.crossSellPairs,
                preferred_main_category: data.preferredMainCategory,
            };
            try {
                await api.recommenders
                    .saveGlobalSetup({ data: mappedData });
                commit('app/STOP_LOADING', null, { root: true });
            } catch (error) {
                api.$handleError(error);
                commit('app/STOP_LOADING', null, { root: true });
                // pass error
                throw error;
            }
        },
        async loadGlobalRecoSettings({ rootState, commit }) {
            commit('app/START_LOADING', null, { root: true });
            try {
                const { data } = await api.recommenders
                    .loadGlobalSetup();
                // data.default_fields
                if (data.default_fields) {
                    Object.keys(data.default_fields).forEach(dField => {
                        if (setup.state.recommenderStructure.attributes[dField]) {
                            setup.state.recommenderStructure.attributes[dField].value_type = data.default_fields[dField].value_type;
                            setup.state.recommenderStructure.attributes[dField].value = data.default_fields[dField].value;
                        }
                    });
                    setup.state.isRecommenderGlobalPresent = true;
                } else {
                    setup.state.isRecommenderGlobalPresent = false;
                }
                if (data.blacklisted_categories) {
                    setup.state.recommenderStructure.categoryBlacklist = data.blacklisted_categories;
                }
                if (data.preferred_main_category?.length > 0) {
                    setup.state.recommenderStructure.preferredMainCategory = data.preferred_main_category;
                }
                if (data.cross_sell_pairs?.length > 0) {
                    setup.state.recommenderStructure.crossSellPairs = data.cross_sell_pairs;
                }
                commit('app/STOP_LOADING', null, { root: true });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                if (error?.response?.status !== 404) {
                    api.$handleError(error);
                    // pass error
                    throw error;
                }
            }
        },
        async deactivateRecommenderSetup({ dispatch, commit, rootState }, type) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.recommenders.deactivateRecommenderSetup({ type });
                await dispatch('saveRecommenderConfig', { publish: true, preview: false, isDisabled: true });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async activateRecommenderSetup({ dispatch, commit, rootState }, type) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.recommenders.activateRecommenderSetup({ type });
                await dispatch('saveRecommenderConfig', { publish: false, preview: false, isDisabled: false });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async enableRecommender({ commit, rootState }, type) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.recommenders.enableRecommenders({ trackerId: rootState.app.siteId });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async enableRecommenderLbx({ commit, rootState }, type) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.recommenders.activateLbx({ trackerId: rootState.app.siteId });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
        async deleteRecommenderSetup({ commit, rootState }, type) {
            commit('app/START_LOADING', null, { root: true });
            try {
                await api.recommenders.deleteRecommenderSetup({ type });
            } catch (error) {
                commit('app/STOP_LOADING', null, { root: true });
                api.$handleError(error);
                return;
            }
            commit('app/STOP_LOADING', null, { root: true });
        },
    },
};

const setupConfigCache = {};
window.setupConfigCache = setupConfigCache;

export const useSetupConfig = type => {
    const store = window.LBXFrontend.$store;

    const refHelper = (pathParam, defaultValue = null) => {
        const computedval = computed({
            get: () => {
                try {
                    let path = pathParam;

                    path = path.split('.');
                    let config = store.state[namespace][type];
                    for (let i = 0; i < path.length; i += 1) {
                        config = config[path[i]];
                    }
                    if (config !== undefined) {
                        return cloneDeep(config);
                    }
                } catch (error) {
                    //
                }
                // if we got here, it is missing
                computedval.value = defaultValue;
                return defaultValue;
            },
            set: payload => {
                let path = pathParam;

                path = path.split('.');
                store.commit(`${namespace}/DEEP_SET`, {
                    type,
                    path,
                    payload,
                });
            },
        });
        return computedval;
    };

    const ref = function ref(pathParam, defaultValue = undefined, debounceTime = 30) {
        // populate with defaultValue first

        // eslint-disable-next-line no-unused-vars
        const computedHelper = refHelper(pathParam, defaultValue);
        // call getter to set default value
        (() => computedHelper.value)();
        const cacheKey = JSON.stringify([...arguments]);
        if (!window.setupConfigCache[cacheKey]) {
            const normalSet = v => {
                window.$EVAL_HELPER = window.LBXFrontend.$store.state[namespace][type];
                window.$EVAL_HELPER2 = v;
                // eslint-disable-next-line no-eval
                eval(`(() => {window.$EVAL_HELPER.${pathParam} = window.$EVAL_HELPER2})()`);
            };
            const delaySet = debounce(normalSet, debounceTime);
            window.setupConfigCache[cacheKey] = computed({
                get() {
                    (() => computedHelper.value)();
                    window.$EVAL_HELPER = window.LBXFrontend.$store.state[namespace][type];
                    // eslint-disable-next-line no-eval
                    return eval(`(() => {return window.$EVAL_HELPER.${pathParam}})()`);
                },
                set(v) {
                    if (debounceTime) {
                        delaySet(v);
                    } else {
                        normalSet(v);
                    }
                },
            });
        }

        return window.setupConfigCache[cacheKey];
    };

    const translation = (path, locale) => {
        const dg = (obj, p) => {
            p = p.split('.');
            for (let i = 0; obj && i < p.length; i += 1) {
                obj = obj[p[i]];
            }
            return obj;
        };

        const ds = (obj, p, value) => {
            p = p.split('.');
            const lastIndex = p.length - 1;
            const last = p[lastIndex];

            for (let i = 0; i < lastIndex; i++) {
                const key = p[i];

                if (!obj[key] && i < lastIndex) {
                    obj[key] = {};
                }
                obj = obj[p[i]];
            }

            // eslint-disable-next-line no-return-assign
            return {
                previous: obj && obj[last],
                current: obj && (obj[last] = value),
            };
        };

        const clearEmpties = o => {
            // eslint-disable-next-line no-restricted-syntax
            for (const k in o) {
                if (!o[k] || typeof o[k] !== 'object') {
                    // eslint-disable-next-line no-continue
                    continue; // If null or not an object, skip to the next iteration
                }

                // The property is an object
                clearEmpties(o[k]); // <-- Make a recursive call on the nested object
                if (Object.keys(o[k]).length === 0) {
                    delete o[k]; // The object had no properties, so delete that property
                }
            }
            return o;
        };

        const getTranslations = () => {
            let translations = {};
            try {
                translations = store.state[namespace][type][type].translations;
            } catch (error) {
            //
            }
            return structuredClone(translations);
        };

        return computed({
            get() {
                const translations = getTranslations();
                return dg(translations, `${locale}.${path}`) || '';
            },
            set(v) {
                let translations = getTranslations();
                ds(translations, `${locale}.${path}`, v || undefined);
                translations = JSON.parse(JSON.stringify(translations));
                translations = clearEmpties(translations);
                store.commit(`${namespace}/DEEP_SET`, {
                    type,
                    path: [type, 'translations'],
                    payload: translations,
                });
            },
        });
    };

    return {
        ref,
        translation,
    };
};
export default setup;
