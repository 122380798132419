import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import { isUserLoggedIn, getHomeRouteForLoggedInUser, canUserNavigateTo } from '@/auth/utils';
import { LEGACY_ROUTE_NAME } from '@/constants';
import i18n, { $t } from '@/libs/i18n';
import store from '@/store';
import apps from './routes/apps';
import dashboard from './routes/dashboard';
import uiElements from './routes/ui-elements/index';
import pages from './routes/pages';
import chartsMaps from './routes/charts-maps';
import formsTable from './routes/forms-tables';
import others from './routes/others';

// ignore NavigationDuplicated error
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
    return new Promise((resolve, reject) => {
        originalPush.call(this, location, () => {
            resolve(this.currentRoute);
        }, error => {
            if (error.name === 'NavigationDuplicated') {
                resolve(this.currentRoute);
            } else {
                reject(error);
            }
        });
    });
};
VueRouter.prototype.replace = function push(location) {
    return new Promise((resolve, reject) => {
        originalReplace.call(this, location, () => {
            resolve(this.currentRoute);
        }, error => {
            if (error.name === 'NavigationDuplicated') {
                resolve(this.currentRoute);
            } else {
                reject(error);
            }
        });
    });
};

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    base: '', // import.meta.env.BASE_URL
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    // routes array is read from top to bottom, first match will render
    routes: [
        {
            name: 'facet_customization',
            path: '/sites/:siteId/facet_customization',
            component: () => import('@/views/CFCustomization.vue'),
            meta: {
                layout: 'default',
                title: 'facetCustomization',
                footer: true,
                progress: true,
            },
        },
        {
            name: 'facet_customization_detail',
            path: '/sites/:siteId/facet_customization/detail',
            component: () => import('@/views/CFCustomizationDetail.vue'),
            meta: {
                layout: 'default',
                title: 'facetCustomizationDetail',
                footer: true,
                progress: true,
            },
        },
        {
            name: 'facet_customization_edit',
            path: '/sites/:siteId/facet_customization/:id',
            component: () => import('@/views/CFCustomizationDetail.vue'),
            meta: {
                layout: 'default',
                title: 'facetCustomizationEdit',
                footer: true,
                progress: true,
            },
        },
        {
            name: 'rewrite_query',
            path: '/sites/:siteId/rewrite_query',
            component: () => import('@/views/query-customization/RewriteQuery.vue'),
            meta: {
                layout: 'default',
                title: 'rewriteQuery',
                footer: true,
                progress: true,
            },
        },
        {
            name: 'rewrite_query_detail',
            path: '/sites/:siteId/rewrite_query/detail',
            component: () => import('@/views/query-customization/RewriteQueryDetail.vue'),
            meta: {
                layout: 'default',
                title: 'rewriteQueryDetail',
                footer: true,
                progress: true,
            },
        },
        {
            name: 'rewrite_query_edit',
            path: '/sites/:siteId/rewrite_query/:id',
            component: () => import('@/views/query-customization/RewriteQueryDetail.vue'),
            meta: {
                layout: 'default',
                title: 'rewriteQueryEdit',
                footer: true,
                progress: true,
            },
        },
        {
            path: '/vuexy-demo',
            component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
            meta: {
                layout: 'vertical',
            },
        },
        {
            path: '/sites/:siteId/dev',
            component: () => import('@/views/Dev.vue'),
            meta: {
                layout: 'default',
                title: 'dev',
                footer: true,
                progress: false,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_plus_flag');
                },
            },
        },
        {
            name: 'code_integration',
            path: '/sites/:siteId/finish_integration/code_integration',
            component: () => import('@/views/onboarding/CodeIntegration.vue'),
            meta: {
                layout: 'default',
                title: 'codeIntegration',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        {
            name: 'finish_integration',
            path: '/sites/:siteId/finish_integration',
            component: () => import('@/views/onboarding/FinishIntegration.vue'),
            meta: {
                layout: 'default',
                title: 'finishIntegration',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        {
            name: 'catalog_integration',
            path: '/sites/:siteId/finish_integration/catalog_integration',
            component: () => import('@/views/onboarding/CatalogIntegration.vue'),
            meta: {
                layout: 'default',
                title: 'catalogIntegration',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        {
            name: 'integration_success',
            path: '/sites/:siteId/finish_integration/integration_success',
            component: () => import('@/views/onboarding/IntegrationSuccess.vue'),
            meta: {
                layout: 'default',
                title: 'integrationSuccess',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        {
            name: 'feeds_adding',
            path: '/sites/:siteId/finish_integration/feeds_adding',
            component: () => import('@/views/onboarding/feeds-integration/FeedsAdding.vue'),
            meta: {
                layout: 'default',
                title: 'feedsIntegration',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        {
            name: 'info',
            path: '/sites/:siteId/finish_integration/feeds_adding/info',
            component: () => import('@/views/onboarding/feeds-integration/FeedsProcessInfo.vue'),
            meta: {
                layout: 'default',
                title: 'feedsIntegration',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        {
            name: 'feeds_mapping',
            path: '/sites/:siteId/finish_integration/feeds_mapping',
            component: () => import('@/views/onboarding/feeds-integration/FeedsMapping.vue'),
            meta: {
                layout: 'default',
                title: 'feedsIntegration',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        {
            name: 'feeds_validation',
            path: '/sites/:siteId/finish_integration/feeds_validation',
            component: () => import('@/views/onboarding/feeds-integration/FeedsValidation.vue'),
            meta: {
                layout: 'default',
                title: 'feedsIntegration',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        {
            name: 'feeds_completed',
            path: '/sites/:siteId/finish_integration/feeds_completed',
            component: () => import('@/views/onboarding/feeds-integration/FeedsCompleted.vue'),
            meta: {
                layout: 'default',
                title: 'feedsIntegration',
                footer: true,
                progress: true,
                notVisibleInfo: true,
            },
        },
        { path: '/vuexy', redirect: { name: 'dashboard-ecommerce' } },
        { path: '/sites/:siteId/settings/scripts', redirect: { name: 'integration_settings' } },
        { path: '/sites/:siteId/settings/setup_shoptet_integration', redirect: { name: 'integration_settings' } },
        { path: '/sites/:siteId/settings/keys', redirect: { name: 'integration_settings' } },
        {
            name: 'recommenders_setup',
            path: '/sites/:siteId/setup/recommenders_self_service',
            component: () => import('@/views/recommender-setup/RSetup.vue'),
            meta: {
                layout: 'default',
                title: 'recommenderSetup',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommender_config_setup');
                },
            },
        },
        {
            name: 'recommenders_setup_category_structure',
            path: '/sites/:siteId/setup/recommenders_self_service/category_structure',
            component: () => import('@/views/recommender-setup/category-structure/CategoryStructure.vue'),
            meta: {
                layout: 'default',
                title: 'recommenderSetup',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommender_config_setup');
                },
            },
        },
        {
            name: 'recommenders_setup_category_structure_blacklist',
            path: '/sites/:siteId/setup/recommenders_self_service/category_structure/category_blacklist',
            component: () => import('@/views/recommender-setup/category-structure/CategoryBlacklist.vue'),
            meta: {
                layout: 'default',
                title: 'recommenderSetup',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommender_config_setup');
                },
            },
        },
        {
            name: 'recommenders_setup_category_structure_cross_sell',
            path: '/sites/:siteId/setup/recommenders_self_service/category_structure/cross_sell',
            component: () => import('@/views/recommender-setup/category-structure/CrossSellMain.vue'),
            meta: {
                layout: 'default',
                title: 'recommenderSetup',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommender_config_setup');
                },
            },
        },
        {
            name: 'recommenders_setup_create',
            path: '/sites/:siteId/setup/recommenders_self_service/create',
            component: () => import('@/views/recommender-setup/RSetupDetailMain.vue'),
            meta: {
                layout: 'default',
                title: 'recommendersSetupDetail',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommender_config_setup');
                },
            },
        },
        {
            name: 'recommenders_setup_detail',
            path: '/sites/:siteId/setup/recommenders_self_service/detail/:settingId',
            component: () => import('@/views/recommender-setup/RSetupDetailMain.vue'),
            meta: {
                layout: 'default',
                title: 'recommendersSetupDetail',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommender_config_setup');
                },
            },
        },
        {
            name: 'recommenders_assignment',
            path: '/sites/:siteId/setup/recommenders_self_service/assignment',
            component: () => import('@/views/recommender-setup/attributes-assignment/AssignmentMain.vue'),
            meta: {
                layout: 'default',
                title: 'recommendersSetupDetail',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommender_config_setup');
                },
            },
        },
        {
            name: 'date_picker',
            path: '/sites/testing/testing_datepicker',
            component: () => import('@/views/testing/DatePickerExample.vue'),
            meta: {
                layout: 'default',
                title: 'datePickerTitle',
                footer: true,
                policies() {
                    return window.LBXFrontendData.isTest;
                },
            },
        },
        /*
    { path: '/', redirect:
      {
        name: 'searches',
        params: {
          siteId: '123-456', // this should be dynamic
        }
      }
    },
    */
        // User roles route
        {
            name: 'user_roles',
            path: '/users/roles',
            component: () => import('@/views/onboarding/UserRoles.vue'),
            meta: {
                layout: 'empty',
                anonymouse: true,
            },
        },
        // signup/registration route
        {
            name: 'signup',
            path: '/users/sign_up',
            component: () => import('@/views/onboarding/Signup.vue'),
            meta: {
                layout: 'empty',
                anonymouse: true,
            },
        },
        // email confirmation route
        {
            name: 'email_confirmation',
            path: '/users/confirmation',
            component: () => import('@/views/onboarding/EmailConfirmation.vue'),
            meta: {
                layout: 'empty',
                anonymouse: true,
            },
        },
        // create project
        {
            name: 'create_project',
            path: '/project/create_project',
            component: () => import('@/views/onboarding/CreateProject.vue'),
            meta: {
                layout: 'empty',
                disableBack: true,
            },
        },
        // create project when existing
        {
            name: 'create_project_existing',
            path: '/sites/:siteId/project/create_project',
            component: () => import('@/views/onboarding/CreateProject.vue'),
            meta: {
                layout: 'empty',
            },
        },
        // create project last step
        {
            name: 'last_step',
            path: '/project/last_step',
            component: () => import('@/views/onboarding/ProjectLastStep.vue'),
            meta: {
                layout: 'empty',
            },
        },
        // create project last step when existing
        {
            name: 'last_step_existing',
            path: '/sites/:siteId/project/last_step',
            component: () => import('@/views/onboarding/ProjectLastStep.vue'),
            meta: {
                layout: 'empty',
            },
        },
        {
            name: 'userInvitation',
            path: '/users/form',
            component: () => import('@/views/onboarding/Signup.vue'),
            meta: {
                layout: 'empty',
                anonymouse: true,
                invitation: true,
            },
        },
        // Login route
        {
            name: 'login',
            path: '/users/sign_in',
            component: () => import('@/views/onboarding/Login.vue'),
            meta: {
                layout: 'empty',
                anonymouse: true,
            },
        },
        // reset password route
        {
            name: 'reset_password',
            path: '/users/password/new',
            component: () => import('@/views/onboarding/ResetPassword.vue'),
            meta: {
                layout: 'empty',
                anonymouse: true,
            },
        },
        // reset password - new password
        {
            name: 'new_password',
            path: '/users/password/edit',
            component: () => import('@/views/onboarding/NewPassword.vue'),
            meta: {
                layout: 'empty',
                anonymouse: true,
            },
        },
        // searches route
        {
            name: 'site_home',
            path: '/sites/:siteId/searches',
            component: () => import('@/views/Empty.vue'),
            meta: {
                legacyRouter: true,
                layout: 'empty',
            },
        },
        {
            name: 'redesign_info',
            path: '/sites/:siteId/help/info_redesign',
            component: () => import('@/views/info/redesign/Redesign.vue'),
            meta: {
                layout: 'default',
                title: 'infoRedesign',
            },
        },
        {
            name: 'shopware_setup',
            path: '/shopware/iframe',
            component: () => import('@/views/shopware/ShopwareSetup.vue'),
            meta: {
                layout: 'empty',
                footer: false,
                anonymouse: true,
            },
        },
        {
            name: 'idosell_setup',
            path: '/idosell/webhooks/iframe',
            component: () => import('@/views/idosell/IdosellSetup.vue'),
            meta: {
                layout: 'empty',
                footer: false,
                anonymouse: true,
            },
        },
        {
            name: 'magento_setup',
            path: '/magento/form',
            component: () => import('@/views/magento/MagentoSetup.vue'),
            meta: {
                layout: 'empty',
                footer: false,
                anonymouse: true,
            },
        },
        {
            name: 'integration_settings',
            path: '/sites/:siteId/settings/integration_settings',
            component: () => import('@/views/settings/IntegrationSettings.vue'),
            meta: {
                layout: 'default',
                footer: true,
                title: 'integrationSettings',
            },
        },
        ...[
            ...apps,
            ...dashboard,
            ...pages,
            ...chartsMaps,
            ...formsTable,
            ...uiElements,
            ...others,
        ].map(x => {
            x.meta = x.meta || {};
            x.meta.layout = 'vertical';
            return x;
        }),
        // if we get user_home, we should reload to /
        {
            path: '/',
            name: 'user_home',
            component: () => import('@/views/loader/Load.vue'),
            meta: {
                layout: 'default',
                footer: false,
                legacyRouter: true,
            },
        },

        {
            name: 'autocomplete_setup',
            path: '/sites/:siteId/setup/autocomplete-self-service',
            component: () => import('@/views/luigis/autocomplete/AutocompleteMain.vue'),
            meta: {
                layout: 'default',
                title: 'acSetup',
                eventSidebar: true,
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('!custom_tag_manager_setup') && (userHasPolicy('autocomplete_ui_setup') || userHasPolicy('search_self_service_ui_setup'));
                },
            },
        },
        {
            name: 'search_setup',
            path: '/sites/:siteId/setup/search-self-service',
            component: () => import('@/views/luigis/search/SearchMain.vue'),
            meta: {
                layout: 'default',
                title: 'searchSetup',
                eventSidebar: true,
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('!custom_tag_manager_setup') && (userHasPolicy('search_ui_setup') || userHasPolicy('search_self_service_ui_setup'));
                },
            },
        },
        // legacy URLs to setups
        { path: '/sites/:siteId/setup/search2', redirect: { name: 'search_setup' } },
        { path: '/sites/:siteId/setup/autocomplete2', redirect: { name: 'autocomplete_setup' } },

        {
            name: 'synonyms',
            path: '/sites/:siteId/synonyms',
            component: () => import('@/views/synonyms/Synonyms.vue'),
            meta: {
                layout: 'default',
                title: 'synonyms',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('synonyms');
                },
            },
        },
        // { path: '/sites/:siteId/catalog_management/synonyms', redirect: { name: 'synonyms', hash: '#list' } },
        // { path: '/sites/:siteId/catalog_management/synonym_recommendations', redirect: { name: 'synonyms', hash: '#recommended' } },
        {
            name: 'auditLog',
            path: '/sites/:siteId/audit_log',
            component: () => import('@/views/audit-log/AuditLog.vue'),
            meta: {
                layout: 'default',
                title: 'auditLog',
                footer: true,
            },
        },
        {
            name: 'recommendersCustomization',
            path: '/sites/:siteId/setup/recommendations_customization',
            component: () => import('@/views/recommenders/RecommendersCustomization.vue'),
            meta: {
                layout: 'default',
                title: 'recommendersCustomization',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommendations_customization');
                },
            },
        },
        {
            name: 'recommendersCustomizationCreate',
            path: '/sites/:siteId/setup/recommendations_customization/create',
            component: () => import('@/views/recommenders/RecommendersCustomizationDetail.vue'),
            meta: {
                layout: 'default',
                title: 'recommendersCustomization',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommendations_customization');
                },
            },
        },
        {
            name: 'recommendersCustomizationDetailCreate',
            path: '/sites/:siteId/setup/recommendations_customization/detail',
            component: () => import('@/views/recommenders/RecommendersCustomizationDetailItem.vue'),
            meta: {
                layout: 'default',
                title: 'recommendersCustomization',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommendations_customization');
                },
            },
        },
        {
            name: 'recommendersCustomizationDetail',
            path: '/sites/:siteId/setup/recommendations_customization/detail/:pinId',
            component: () => import('@/views/recommenders/RecommendersCustomizationDetailItem.vue'),
            meta: {
                layout: 'default',
                title: 'recommendersCustomization',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('recommendations_customization');
                },
            },
        },
        {
            name: 'ranking',
            path: '/sites/:siteId/search_ranking',
            component: () => import('@/views/ranking/RankingMain.vue'),
            meta: {
                layout: 'default',
                title: 'ranking',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'siteConfiguration',
            path: '/sites/:siteId/site_configuration',
            component: () => import('@/views/configurations/SiteConfiguration.vue'),
            meta: {
                layout: 'default',
                title: 'siteConfiguration',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'allConfigurations',
            path: '/sites/:siteId/all_configurations',
            component: () => import('@/views/configurations/AllConfigurations.vue'),
            meta: {
                layout: 'default',
                title: 'allConfigurations',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'configurationVersionDetail',
            path: '/sites/:siteId/all_configurations/version/:id/:version',
            component: () => import('@/views/configurations/ConfigurationVersionDetail.vue'),
            meta: {
                layout: 'default',
                title: 'configurationDetail',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'configurationDetail',
            path: '/sites/:siteId/all_configurations/detail/:id',
            component: () => import('@/views/configurations/ConfigurationDetail.vue'),
            meta: {
                layout: 'default',
                title: 'configurationDetail',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'configurationCreate',
            path: '/sites/:siteId/all_configurations/create_configuration',
            component: () => import('@/views/configurations/CreateConfiguration.vue'),
            meta: {
                layout: 'default',
                title: 'configurationCreate',
                notVisibleInfo: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'abTests',
            path: '/sites/:siteId/ab_testing',
            component: () => import('@/views/ab-tests/AbTests.vue'),
            meta: {
                layout: 'default',
                title: 'abTests',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'abTestsCreate',
            path: '/sites/:siteId/ab_testing/create',
            component: () => import('@/views/ab-tests/AbTestsDetail.vue'),
            meta: {
                layout: 'default',
                title: 'abTests',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'abTestsDetail',
            path: '/sites/:siteId/ab_testing/detail/:id',
            component: () => import('@/views/ab-tests/AbTestsDetail.vue'),
            meta: {
                layout: 'default',
                title: 'abTests',
                footer: true,
                policies({ userHasPolicy }) {
                    return userHasPolicy('internal_flag')
                    || userHasPolicy('hasCSM');
                },
            },
        },
        {
            name: 'sitesGroups',
            path: '/sites/:siteId/sites_groups',
            component: () => import('@/views/sites-groups/SitesGroups.vue'),
            meta: {
                layout: 'default',
                title: 'sitesGroups',
                footer: true,
            },
        },
        {
            name: 'onboarding',
            path: '/users/onboarding',
            component: () => import('@/views/onboarding/Onboarding.vue'),
            meta: {
                layout: 'empty',
                anonymouse: true,
            },
        },
        {
            name: 'onboarding_success',
            path: '/sites/:siteId/onboarding_success',
            component: () => import('@/views/onboarding/OnboardingSuccess.vue'),
            meta: {
                layout: 'default',
                title: 'oneMinuteOnboardingSuccess',
                footer: true,
            },
        },
        // last route - match everything and return 404
        {
            path: '/*',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
    ],
});

const DEFAULT_QUERIES = [
    'period',
    'starting_at',
    'ending_at',
    'with_ip',
    'without_ip',
    'with_list_w_filter_pairs',
    'with_url_w_param_pairs',
    'without_url_w_param_pairs',
    'with_url_steps',
    'with_referer_steps',
    'without_referer_steps',
    'without_queries',
    'with_any_filter',
    'apply_segment',
    'conversions_mode',
];

let disableNextRouteChange = false;

const routerHistory = [];

router.beforeEach(async (to, from, next) => {
    const lastHistoryItem = routerHistory[routerHistory.length - 2] || null;
    if (lastHistoryItem && to.name === lastHistoryItem.name && lastHistoryItem.meta.disableBack) {
        // eslint-disable-next-line no-console
        console.error('Navigation prevented be meta.disableBack');
        next(false);
        return false;
    }
    if (disableNextRouteChange) {
        return false;
    }
    if (store.state.app.leaveBlockCounter) {
        // get last one
        const customLeaveBlocker = store.state.app.customLeaveBlockers[
            store.state.app.customLeaveBlockers.length - 1
        ];
        if (customLeaveBlocker) {
            const leave = await customLeaveBlocker();
            if (!leave) {
                return next(false);
            }
            store.commit('app/UNBLOCK_LEAVE', true);
        } else {
            // eslint-disable-next-line no-alert, no-restricted-globals
            if (!confirm($t('app.notifications.leave'))) {
                return next(false);
            }
            store.commit('app/UNBLOCK_LEAVE', true);
        }
    }

    let modified = false;
    const url = new URL(window.location.href);
    if (to.name !== 'site_home') {
        DEFAULT_QUERIES.forEach(qName => {
            if (to.query[qName] === undefined && url.searchParams.get(qName) !== null) {
                to.query[qName] = url.searchParams.get(qName);
                modified = true;
            }
        });
    }

    if (modified) {
        return next(to);
    }

    if (to.meta.legacyRouter || to.name === LEGACY_ROUTE_NAME) {
        window.history.pushState({ rand: new Date().getTime() }, null, window.location.href);
        next(false);
        disableNextRouteChange = true;
        window.location.href = to.fullPath || to.path;
        setTimeout(() => {
            disableNextRouteChange = false;
        }, 1);
        return undefined;
    }

    const isLoggedIn = isUserLoggedIn();

    if (!canUserNavigateTo(to)) {
        if (!isLoggedIn && to.meta?.anonymouse) return next();
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'login' });

        // If logged in => not authorized

        // we dont want to redirect to not_authorized route because URL would change
        // this way, URL points where user wanted to be and
        // v-if="notAuthorizedVisible" in LayoutDefault
        // is used to hide restricted component
        if (!to.meta?.NOT_AUTHORIZED) {
            // this must be in if branch in order to stop infinite recursion
            const newTo = { ...to };
            newTo.meta = newTo.meta || {};
            newTo.meta.NOT_AUTHORIZED = true;
            return next(newTo);
        }
        return next();
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        return next(getHomeRouteForLoggedInUser());
    }
    return next();
});

router.afterEach(to => {
    if (to.params.siteId) {
        store.commit('app/SET_SITE_ID', to.params.siteId);
    } else if (window.LBXFrontendData?.siteId) {
        store.commit('app/SET_SITE_ID', window.LBXFrontendData.siteId);
    }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
    routerHistory.push(to);
    if (to.meta.title) {
        window.document.title = `${$t(`documentTitles.${to.meta.title}`)} | ${$t('documentTitles._default')}`;
    } else {
        window.document.title = $t('documentTitles._default');
    }

    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
    store.dispatch('app/pageView');
});

export default router;
