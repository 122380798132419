import { SESSION_STATE_STORAGE_KEY } from '@/constants';

const local = {
    namespaced: true,
    state: {
        hideRedesignModal: false,
    },
    getters: {
    },
    mutations: {
        RESTORE_STATE(state, payload) {
            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            });
        },
        SET_HIDE_REDESIGN_MODAL(state, payload) {
            state.hideRedesignModal = payload;
        },
    },
    actions: {
        start({ commit, dispatch }) {
            const localState = JSON.parse(sessionStorage.getItem(SESSION_STATE_STORAGE_KEY));
            if (localState) {
                commit('RESTORE_STATE', localState);
            }
            dispatch('save');
            this.subscribe(mutation => {
                if (mutation.type.startsWith('session/')) {
                    dispatch('save');
                }
            });
        },
        save({ state }) {
            sessionStorage.setItem(SESSION_STATE_STORAGE_KEY, JSON.stringify(state));
        },
    },
};

export default local;
