// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
    return !!window.LBXFrontendData?.user?.id;
};

export const getUserData = () => window.LBXFrontendData?.user;

export const getHomeRouteForLoggedInUser = () => ({
    name: 'user_home',
});

export const userHasPolicy = policy => {
    const loggedIn = isUserLoggedIn();
    if (!loggedIn) {
        return false;
    }

    // negative
    if (policy.startsWith('!')) {
        return !window.LBXFrontendData?.policies[policy.replace('!', '')];
    }

    return !!window.LBXFrontendData?.policies[policy];
};

export const userHasRole = role => {
    const loggedIn = isUserLoggedIn();
    if (!loggedIn) {
        return false;
    }

    // negative
    if (role.startsWith('!')) {
        return !window.LBXFrontendData?.user?.roles?.includes(role.replace('!', ''));
    }

    return !!window.LBXFrontendData?.user?.roles?.includes(role);
};

export const checkPolicies = policies => {
    if (!policies) {
        return true;
    }
    if (Array.isArray(policies)) {
        let missingPolicy = false;
        policies.forEach(policy => {
            if (!userHasPolicy(policy)) {
                missingPolicy = true;
            }
        });
        return !missingPolicy;
    } if (typeof policies === 'function') {
        return !!policies({ userHasPolicy, userHasRole, isUserLoggedIn });
    }
    // eslint-disable-next-line no-console
    console.error('Bad policy', to);
    throw new Error('bad-policy');
};

export const checkRoles = roles => {
    if (!roles) {
        return true;
    }
    if (Array.isArray(roles)) {
        let missingRole = false;
        roles.forEach(role => {
            if (!userHasRole(role)) {
                missingRole = true;
            }
        });
        return !missingRole;
    }
    if (typeof roles === 'function') {
        return !!roles({ userHasPolicy, userHasRole, isUserLoggedIn });
    }
    // eslint-disable-next-line no-console
    console.error('Bad role', to);
    throw new Error('bad-role');
};

export const canUserNavigateTo = to => {
    const loggedIn = isUserLoggedIn();
    const policies = to.meta?.policies;
    const roles = to.meta?.roles;

    if (!loggedIn && !to.meta?.allowNotLoggedIn) {
        return false;
    }

    if (!checkPolicies(policies)) {
        return false;
    }
    if (!checkRoles(roles)) {
        return false;
    }

    return true;
};
