import { LOCAL_STATE_STORAGE_KEY } from '@/constants';

const local = {
    namespaced: true,
    state: {
        menuCollapsedCategories: [],
        isMenuHidden: false,
        hideRedesignModal: false,
        recentProjects: [],
        sitesCache: null,
        sitesCacheTimestamp: 0,
        hiddenGroupsInMenu: [],
    },
    getters: {
        isMenuCategoryCollapsed: state => ({ id, category }) => {
            if (category === 'generalHelp') {
                // negative condition, default is hidden
                return !state.menuCollapsedCategories.find(x => x === `${id}:${category}`);
            }
            return !!state.menuCollapsedCategories.find(x => x === `${id}:${category}`);
        },
    },
    mutations: {
        RESTORE_STATE(state, payload) {
            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            });
        },
        SET_IS_MENU_HIDDEN(state, payload) {
            state.isMenuHidden = payload;
        },
        SET_HIDE_REDESIGN_MODAL(state, payload) {
            state.hideRedesignModal = payload;
        },
        SET_HIDDEN_GROUPS_IN_MENU(state, payload) {
            state.hiddenGroupsInMenu = payload;
        },
        TOGGLE_COLLAPSE_MENU_CATEGORY(state, { id, category }) {
            let isCollapsed = local.getters.isMenuCategoryCollapsed(state)({ id, category });
            if (category === 'generalHelp') {
                isCollapsed = !isCollapsed;
            }
            if (isCollapsed) {
                const index = state.menuCollapsedCategories.findIndex(x => x === `${id}:${category}`);
                if (index > -1) {
                    state.menuCollapsedCategories.splice(index, 1);
                }
            } else {
                state.menuCollapsedCategories.push(`${id}:${category}`);
            }
        },
        ADD_RECENT_PROJECT(state, projectId) {
            if (!projectId) {
                return;
            }
            const recent = [...state.recentProjects];
            recent.push(projectId.toString());
            // reverse twice to remove duplicate from start
            // and save only last 5
            state.recentProjects = [...new Set(recent.reverse())]
                .reverse()
                .slice(-5);
        },
        CACHE_SITES(state, sites) {
            if (typeof sites !== 'object') {
                return;
            }
            state.sitesCacheTimestamp = new Date().getTime();
            state.sitesCache = sites;
        },
    },
    actions: {
        start({ commit, dispatch }) {
            const localState = JSON.parse(localStorage.getItem(LOCAL_STATE_STORAGE_KEY));
            if (localState) {
                commit('RESTORE_STATE', localState);
            }
            dispatch('save');
            this.subscribe(mutation => {
                if (mutation.type.startsWith('local/')) {
                    dispatch('save');
                }
            });
        },
        save({ state }) {
            localStorage.setItem(LOCAL_STATE_STORAGE_KEY, JSON.stringify(state));
        },
    },
};

export default local;
