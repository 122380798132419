// this is directive to improve UX on selects with search
// this components are build using b-dropdown and some input
// add v-dropdown-keyboard-helper or v-dropdown-keyboard-helper=".input-selector" to <b-dropdown />

export const dropdownKeyboardHelper = {
    inserted: (el, binding) => {
        const listener = e => {
            const { target, keyCode } = e;

            // some people are scared of pressing ENTER, trust issues or idk
            // so SPACE should also select item
            if (target.matches('.dropdown-item') && keyCode === 32) {
                target.click();
                return;
            }
            // open closed dropdown on simple key press
            if (target.matches('.dropdown-toggle')) {
                // normal "alphanumeric" key
                // stupid solution but works for most cases
                const isSimpleKey = typeof e.key === 'string' && e.key.length === 1;
                if (!isSimpleKey) {
                    return;
                }
                const visibleMenu = target.parentNode.querySelector('.dropdown-menu.show');
                // if items are already visible, skip
                if (visibleMenu) {
                    return;
                }
                const inputSelector = binding.value || 'input[type="text"';
                const input = target.parentNode.querySelector(inputSelector);
                // append key to input value
                if (input) {
                    input.value += e.key;
                    const inputEvent = new Event('input', {
                        bubbles: true,
                        cancelable: true,
                    });
                    // trigger input to update v-model
                    input.dispatchEvent(inputEvent);
                    setTimeout(() => {
                        input.focus();
                    }, 0);
                }
                // open dropdown
                target.click();
            }
        };
        el.addEventListener('keydown', listener);
        // eslint-disable-next-line no-underscore-dangle
        el.__dropdownKeyboardListener = listener;
    },
    unbind: el => {
        // eslint-disable-next-line no-underscore-dangle
        const listener = el.__dropdownKeyboardListener;
        el.removeEventListener('keydown', listener);
        // eslint-disable-next-line no-underscore-dangle
        delete el.__dropdownKeyboardListener;
    },
};