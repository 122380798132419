<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <b-alert
      :show="feedFailed"
      variant="danger"
      dismissible
      class="lb-failed-feed p-1 ml-1 mr-1 align-items-center"
      @dismissed="removeFailedFeedStatus"
    >
      <span class="text-dark font-weight-normal">
        <feather-icon
          icon="AlertTriangleIcon"
          class="mr-1"
          size="16"
        />
        {{ $t('feedFailedWarning.info') }}&nbsp;<a :href="`/sites/${siteId}/settings/feeds/history`" target="_blank"> {{ $t('feedFailedWarning.link') }}</a>
      </span>
    </b-alert>
    <component
      :is="layout"
      :class="feedFailed ? 'lb-background-disabled' : ''"
    >
      <router-view />
    </component>
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue';

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { ref, watch } from 'vue';
import useAppConfig from '@core/app-config/useAppConfig';

import { useWindowSize, useCssVar } from '@vueuse/core';

import store from '@/store';

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue');
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');
const LayoutEmpty = () => import('@/layouts/empty/LayoutEmpty.vue');
const LayoutDefault = () => import('@/layouts/default/LayoutDefault.vue');

export default {
    components: {

        // Layouts
        LayoutHorizontal,
        LayoutVertical,
        LayoutFull,
        LayoutEmpty,
        LayoutDefault,

        ScrollToTop,
    },
    setup() {
        const { skin, skinClasses } = useAppConfig();
        const { enableScrollToTop } = $themeConfig.layout;

        const siteId = ref(store.state.app.siteId);

        // If skin is dark when initialized => Add class to body
        if (skin.value === 'dark') document.body.classList.add('dark-layout');

        // Set Window Width in store
        store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
        const { width: windowWidth } = useWindowSize();
        watch(windowWidth, val => {
            store.commit('app/UPDATE_WINDOW_WIDTH', val);
        });

        const feedFailed = ref(window.LBXFrontendData?.policies?.last_job_failed);

        const checkFeedStatus = () => {
            const feedStatus = window.sessionStorage.getItem('lbFailedFeed');
            if (feedStatus && feedStatus.exp > new Date().getTime()) {
                feedFailed.value = feedStatus.data;
            } else {
                feedFailed.value = window.LBXFrontendData?.policies?.last_job_failed;
            }
        };
        checkFeedStatus();

        const removeFailedFeedStatus = () => {
            feedFailed.value = false;
            window.sessionStorage.setItem('lbFailedFeed', JSON.stringify({ exp: new Date().getTime() + 1000 * 60 * 60, data: feedFailed.value }));
        };

        return {
            skinClasses,
            enableScrollToTop,
            siteId,
            feedFailed,
            removeFailedFeedStatus,
        };
    },
    // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
    // Currently, router.currentRoute is not reactive and doesn't trigger any change
    computed: {
        layout() {
            if (this.$route.meta.layout === 'default') return 'layout-default';
            if (this.$route.meta.layout === 'full') return 'layout-full';
            if (this.$route.meta.layout === 'vertical') return 'layout-vertical';
            if (this.$route.meta.layout === 'empty') return 'layout-empty';
            return `layout-${this.contentLayoutType}`;
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type;
        },
    },
    beforeCreate() {
    // Set colors in theme
        const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = colors.length; i < len; i++) {
            $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
        }

        // Set Theme Breakpoints
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2));
        }

        // Set RTL
        const { isRTL } = $themeConfig.layout;
        document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
    },
};
</script>

<style lang="scss">
.lb-failed-feed {
  position: absolute !important;
  z-index: 999999;
  right: 0;
  margin-top: 70px;
  left: 50%;
  transform: translate(-50%, -50%);
  &__danger {
    background: #FCEAEB !important;
    margin: auto !important;
    width: 50% !important;
  }
  &__to-right {
    text-align: right;
  }
  &__icon {
    font-size: 17px;
    padding-right: 8px;
    vertical-align: middle;
  }
  &__cross {
    font-size: 14px;
    cursor: pointer;
    color: black;
  }
  button.close {
    padding: 0.71rem 1rem !important;
    color: black !important;
    font-weight: 100 !important;
    opacity: 1 !important;
  }
}
.lb-background-disabled {
  opacity: 0.2;
}
#app .alert-dismissible {
  padding-right: 28px !important;
}
@media(max-width: 1300px) {
  .lb-failed-feed {
    width: 75%;
  }
}
</style>
