import { $themeBreakpoints } from '@themeConfig';
import $axios from '@/libs/axios';
import { $t } from '@/libs/i18n';

import { sendDataReadyEvent } from '@/libs/analytics';

import { RELOAD_SITES } from '@/constants';

const leaveEventListener = e => {
    const msg = $t('app.notifications.leave');
    e.returnValue = msg;
    return msg;
};

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,
        progressPercentage: 0,
        leaveBlockCounter: 0,
        customLeaveBlockers: [],
        sites: [],
        trialDays: null,
        siteId: null,
        loadingCounter: 0,
        syncCompleted: false,
        lbxCodeIncluded: false,
        hasSearch: false,
        hasAutocomplete: false,
        hasRecommender: false,
        isBeIntegrated: false,
        catalogIntegration: null,
        isSelfService: false,
        errorCatalogMessage: null,
    },
    getters: {
        currentBreakPoint: state => {
            const { windowWidth } = state;
            if (windowWidth >= $themeBreakpoints.xl) return 'xl';
            if (windowWidth >= $themeBreakpoints.lg) return 'lg';
            if (windowWidth >= $themeBreakpoints.md) return 'md';
            if (windowWidth >= $themeBreakpoints.sm) return 'sm';
            return 'xs';
        },
        currentSite: state => {
            const { sites, siteId } = state;
            return (sites.reduce((prev, curr) => {
                // reduce level
                (() => {})(); // this is just for eslint
                return [...prev, ...curr.sites];
            },
            []).find(x => x.id === siteId));
        },
        isLoading: state => state.loadingCounter > 0,
    },

    mutations: {
        START_LOADING(state) {
            state.loadingCounter += 1;
        },
        STOP_LOADING(state, force = false) {
            let newCounter = state.loadingCounter - 1;
            if (newCounter < 0 || force === true) {
                newCounter = 0;
            }
            state.loadingCounter = newCounter;
        },
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val;
        },
        SET_PROGRESS(state, val) {
            if (state.progressPercentage < 100) {
                state.progressPercentage = Math.min(val, 100);
            }
        },
        SET_SYNC_COMPLETION(state, val) {
            state.syncCompleted = val;
        },
        SET_SEARCH_PRESENT(state, val) {
            state.hasSearch = val;
        },
        SET_AUTOCOMPLETE_PRESENT(state, val) {
            state.hasAutocomplete = val;
        },
        SET_RECOMMENDER_PRESENT(state, val) {
            state.hasRecommender = val;
        },
        SET_LBX_CODE_INCLUDED(state, val) {
            state.lbxCodeIncluded = val;
        },
        SET_IS_BE_INTEGRATED(state, val) {
            state.isBeIntegrated = val;
        },
        SET_CATALOG_INTEGRATION(state, val) {
            state.catalogIntegration = val;
        },
        SET_IS_SELF_SERVICE(state, val) {
            state.isSelfService = val;
        },
        SET_ERROR_CATALOG_MESSAGE(state, val) {
            state.errorCatalogMessage = val;
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
        },
        SET_SITES(state, val) {
            const recentProjects = [...this.state.local.recentProjects];
            const projects = [...val];
            recentProjects.forEach(projectId => {
                const index = projects.findIndex(p => p.id === projectId);
                if (index > -1) {
                    // move project to start of array
                    const projectToMove = { ...projects[index] };
                    projects.splice(index, 1);
                    projects.unshift(projectToMove);
                }
            });
            state.sites = projects;
        },
        SET_SITE_ID(state, val) {
            state.siteId = val;
        },
        ADD_CUSTOM_LEAVE_BLOCKER(state, payload) {
            state.customLeaveBlockers.push(payload);
        },
        REMOVE_CUSTOM_LEAVE_BLOCKER(state) {
            state.customLeaveBlockers.pop();
        },
        BLOCK_LEAVE(state) {
            state.leaveBlockCounter += 1;
            if (state.leaveBlockCounter === 1) {
                window.addEventListener('beforeunload', leaveEventListener);
            }
        },
        UNBLOCK_LEAVE(state, force = false) {
            if (force === true) {
                state.leaveBlockCounter = 0;
            } else if (state.leaveBlockCounter > 0) {
                state.leaveBlockCounter -= 1;
            }

            if (state.leaveBlockCounter === 0) {
                window.removeEventListener('beforeunload', leaveEventListener);
            }
        },
        SET_TRIAL_DAYS(state, val = undefined) {
            if (val === undefined) {
                // eslint-disable-next-line no-param-reassign
                val = window.LBXFrontendData.trial?.days || null;
            }
            state.trialDays = val;
        },
    },
    actions: {
        start({ dispatch }) {
            // start storages
            dispatch('local/start', null, { root: true });
            dispatch('session/start', null, { root: true });
            if (sessionStorage.getItem(RELOAD_SITES)) {
                dispatch('loadSites', true);
            }
        },
        loadSitesNextTime() {
            sessionStorage.setItem(RELOAD_SITES, 1);
        },
        async loadSites({ commit, rootState }, forceCacheReload = false) {
            const { trackingCode } = window.LBXFrontendData.siteData;
            const now = new Date().getTime();
            const expireAfter = 60 * 60 * 1000;

            sessionStorage.removeItem(RELOAD_SITES);

            let data;

            if (
                forceCacheReload
                || !rootState.local.sitesCache
                || now - rootState.local.sitesCacheTimestamp > expireAfter
            ) {
                data = (await $axios.get(`/sites/${trackingCode}/available_projects`)).data;
                commit('local/CACHE_SITES', data, { root: true });
            } else {
                data = rootState.local.sitesCache;
            }
            const loadedSites = [];
            Object.keys(data || {}).forEach(projectId => {
                const projectData = data[projectId];
                loadedSites.push({
                    title: projectData.name,
                    persoo: projectData.persoo,
                    id: projectId,
                    groups: projectData.groups,
                    sites: (projectData?.sites || []).map(site => ({
                        id: `${projectId}-${site.id}`,
                        site: site.name,
                    })),

                });
            });
            // add current project to recent
            commit('local/ADD_RECENT_PROJECT', window.LBXFrontendData?.siteData?.projectId, { root: true });
            commit('SET_SITES', loadedSites);
        },
        pageView() {
            sendDataReadyEvent();
        },
    },
};
