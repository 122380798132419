<template>
  <span class="info-tooltip">
    <span
      v-show="false"
      ref="slotWrapper"
    >
      <slot />
    </span>
    <feather-icon
      v-if="tooltipHtml"
      v-b-tooltip.hover.top.html="tooltipHtml"
      icon="InfoIcon"
    />
  </span>
</template>

<script>
import {
    onMounted, ref,
} from 'vue';

export default {
    setup() {
        const tooltipHtml = ref('');
        const slotWrapper = ref(null);
        onMounted(async () => {
            const helper = document.createElement('DIV');
            helper.innerHTML = slotWrapper.value.innerHTML;

            [...helper.querySelectorAll('*')].forEach(innerEl => {
                [...innerEl.attributes].forEach(attr => {
                    if (attr.name.startsWith('data-v-')) {
                        innerEl.removeAttribute(attr.name);
                    }
                });
            });

            tooltipHtml.value = helper.innerHTML;
        });

        return {
            slotWrapper,
            tooltipHtml,
        };
    },
};
</script>

<style>

</style>
