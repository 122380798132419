<template>
  <b-button-orig
    v-ripple.400="ripple || 'transparent'"
    v-bind="$attrs"
    class="lbx-button"
    :class="{'lbx-button--loading' : ajaxActive}"
    :disabled="computedDisabled"
    @click="onClick"
  >
    <span class="lbx-button__content">
      <slot />
    </span>
    <span
      v-if="ajaxActive || loading"
      class="lbx-button__spinner"
    >
      <b-spinner />
    </span>
  </b-button-orig>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import { ref, computed } from 'vue';

import {
    BButton,
    BSpinner,
} from 'bootstrap-vue';

export default {
    components: {
        'b-button-orig': BButton,
        BSpinner,
    },
    directives: {
        Ripple,
    },
    props: {
        ripple: {
            type: String,
            // pass false to disable
            default: 'rgba(255, 255, 255, 0.15)',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        ajaxClick: {
            type: Function,
            default: null,
        },
    },
    setup(props, { emit }) {
        const ajaxActive = ref(false);

        const computedDisabled = computed(() => props.disabled || ajaxActive.value);

        const onClick = async (...args) => {
            if (props.ajaxClick) {
                if (ajaxActive.value) {
                    return;
                }
                emit('click', ...args);
                ajaxActive.value = true;
                await props.ajaxClick(...args);
                ajaxActive.value = false;
            } else {
                emit('click', ...args);
            }
        };

        return {
            computedDisabled,
            onClick,
            ajaxActive,
        };
    },
};
</script>

<style lang="scss" scoped>
  .lbx-button
  {
    position: relative
  }

  .lbx-button__spinner
  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lbx-button--loading .lbx-button__content
  {
    opacity: 0;
  }
</style>
